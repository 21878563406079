import { ActionModel } from '../models/action';
import { ActionListModel } from '../models/general/action-list';
import { ChannelTypeModel } from '../models/general/channel-type-model';
import { NameValue } from '../models/general/name-value';
import { BaseMenu } from '../models/menu/base-menu';
import { ValueDisplay } from '../models/value-display';
import { ValueDisplayString } from '../models/value-display-string';

export enum RouteUrl {

  dashboard = 'app/dashboard',

  browserUpgrade = 'browser-upgrade',

  //#region ACTIVITY
  authorizations = 'app/authorizations',
  // reservations = 'app/reservation',
  chargingSessions = 'app/charging-sessions',
  transaction = 'app/transactions',
  razorpayTransaction = 'app/razorpay-transaction',
  phonepeTransaction = 'app/phonepe-transaction',
  stripeTransaction = 'app/stripe-transaction',
  reservation = 'app/reservation',
  //#endregion

  //#region ASSETS
  chargePoints = 'app/charge-points',
  evses = 'app/evse',
  connectors = 'app/connectors',
  chargingZones = 'app/charging-zones',
  locations = 'app/locations',
  locationImages = 'app/location-images',
  compactChargePoints = 'app/compact-charge-points',
  carbonCreditAgency = 'app/carbon-credit-agency',
  //chargingProfile = 'app/charging-profile',
  //#endregion

  //#region CRM
  users = 'app/users',
  rfidTags = 'app/rfid-tags',
  //#endregion

  //#region public evse qr code
  publicevse = '/public/evse/',
  //#endregion

  //#region PARTNERS
  partners = 'app/partners',
  partnerInvites = 'app/partner-invites',
  partnerContracts = 'app/partner-contracts',
  //#endregion

  //#region ANALYTICS
  reports = 'app/reports/reports',
  shareReports = 'app/reports/channel-report',
  serviceCostReport = 'app/reports/service-cost-report',
  sessionReport = 'app/reports/session-report',
  sessionRevenueReport = 'app/reports/session-revenue-report',
  energyConsumptionReport = 'app/reports/energy-consumption-report',
  chargePointUptimeReport = 'app/reports/chargepoint-uptime-report',
  chargePointDurationReport = 'app/reports/charging-duration-report',
  phonepeQRcodeReport = 'app/reports/phonepe-qrcode-report',
  ccReport = 'app/reports/carbon-credit-report',
  chargePointEventsReport = 'app/reports/chargepoint-events-report',
  //#endregion

  //#region ANALYTICS
  device = 'app/device',
  //#endregion

  //#region CONFIGURATION
  admins = 'app/admins',
  client = 'app/clients',
  clientDetail = 'app/company-detail',
  //#endregion

  //#region AUTHENTICATION
  login = 'auth/login',
  forgotPassword = 'auth/forgot-password',
  resetPassword = 'auth/reset-password',
  changePassword = 'app/change-password',
  //#endregion

  //#region LOGS
  statusLogs = 'app/status-logs',
  communicationLogs = 'app/communication-logs',
  reqResLogs = 'app/request-response-log',
  //#endregion

  //#region CONFIGURATIONS
  configurations = 'app/configurations',
  pushNotification = 'app/push-notification',
  customPushNotification = 'app/custom-push-notification',
  //#endregion

  //#region TARIFFS
  tariffs = 'app/tariff',
  //#endregion

  //#region RESERVATION TARIFFS
  reservationTariff = 'app/reservation-tariff',
  //#endregion

  //#region CURRENCY
  currency = 'app/currency',
  //#endregion

  //#region PAYMENT PROCESSOR
  paymentProcessor = 'app/payment-processor',
  //#endregion

  //#region RAZORPAY TRANSACTION
  // razorPay = 'app/razorpay-transaction',
  // phonePe = 'app/phonepe-transaction',
  // stripe = 'app/stripe-transaction',
  //#endregion

  //#region PAYMENT PROCESSOR
  wallet = 'app/wallet',
  //#endregion


  //#region ROUTE-PREFIX
  new = 'new',
  update = 'update',
  partnerAdmin = 'partnerAdmin',
  importCSV = 'import-csv',
  //#endregion

  //#region partner company detail
  partnerCompanyDetail = 'app/partner-company-detail',
  //#endregion

  //#region SigFox Data
  sigFoxData = 'app/sigfox-data',
  //#endregion

  //#region FAQ
  faq = "app/faq",
  faqWebView = "list",
  //#endregion

  //#region Amenity
  amenity = 'app/amenity',

  //#endregion

  //#Vehicle Management
  vehicleCompany = "app/vehicle-company",
  vehicleModel = "app/vehicle-model",
  //#endregion

  smstemplate = 'app/sms-template',

  channel = 'app/channel',
  ownerList = 'owner/list',
  softwareCost = 'app/software-cost',

  coupanCode = 'app/coupon-code/pending-users',
  approvedCoupanCode = 'app/coupon-code/approved-users',
  chargingProfile = 'app/charging-profile',
  //#EMSP
  EMSP = 'app/emsp',
  EMSPwalletSettlement = 'app/emsp-wallet-settlement',
  //#endregion

  //#CPO
  CPOVersion = 'app/cpo-version',
  CPOEndpoint = 'app/cpo-endpoint',
  CPORegistration = 'app/cpo-registration',
  OcpiReport = 'app/reports/ocpi-session-report',
  //#endregion

  //#region WALLET SETTLEMENT
  walletSettlement = 'app/wallet-settlement',
  //#endregion

  //#region FIRMWARE
  firmwareDetails = 'app/firmware-details',
  firmwareUpdateHistory = "app/firmware-update-history"
  //#endregion
}


export enum ChannelTypeEnum {
  VM = 'VM'
}

export const requestTypeEnum = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved'
} as const;

export enum AdminRoles {

  //admin = 'VerdeAdmin',
  analyst = 'Analyst',
  superadmin = 'SuperAdmin',

  verdeAdmin = 'VerdeAdmin',
  landOwnerAnalyst = 'LandOwnerAnalyst',
  landOwnerAgencyAnalyst = 'LandOwnerAgencyAnalyst',
  dealerAnalyst = 'DealerAnalyst',
  distributorAnalyst = 'DistributorAnalyst',
  sfAnalyst = 'SFAnalyst',
  msfAnalyst = 'MSFAnalyst',
  vmAnalyst = 'VMAnalyst',
  operatorWithoutTariff = 'OperatorWithoutTariff',
  operatorWithTariff = 'OperatorWithTariff',
  operator = 'Operator'

}

export const verdeAdminMenu: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Locations',
        id: 'location',
        icon: 'fas fa-map-marked-alt',
        route: '/' + RouteUrl.locations,
        menuChildren: [],
      },
      {
        displayName: 'Zones',
        id: 'zone',
        icon: 'fas fa-map-marker-alt',
        route: '/' + RouteUrl.chargingZones,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
      {
        displayName: 'Reservation Tariffs',
        id: 'ReservationTarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.reservationTariff,
        menuChildren: [],
      },
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Charging Profile',
        id: 'ChargeProfile',
        icon: 'fas fa-calendar-times',
        route: '/' + RouteUrl.chargingProfile,
        menuChildren: [],
      },
      {
        displayName: 'Vehicle Company',
        id: 'VehicleCompany',
        icon: 'fas fa-car',
        route: '/' + RouteUrl.vehicleCompany,
        menuChildren: [],
      },
      {
        displayName: 'Vehicle Model',
        id: 'VehicleModel',
        icon: 'fas fa-car-side',
        route: '/' + RouteUrl.vehicleModel,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Agency',
        id: 'carbonCreditAgency',
        icon: 'fas fa-user-circle',
        route: '/' + RouteUrl.carbonCreditAgency,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Firmware Update',
        id: 'FirmwareUpdates',
        icon: 'fas fa-upload',
        route: '/' + RouteUrl.firmwareUpdateHistory,
        menuChildren: []
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
      {
        displayName: 'Razorpay Transactions',
        id: 'RazorpayTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.razorpayTransaction,
        menuChildren: [],
      },
      {
        displayName: 'Phonepe Transactions',
        id: 'PhonepeTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.phonepeTransaction,
        menuChildren: [],
      },
      {
        displayName: 'Stripe Transactions',
        id: 'StripeTransaction',
        icon: 'pi pi-credit-card',
        route: '/' + RouteUrl.stripeTransaction,
        menuChildren: [],
      },
      {
        displayName: 'UPI Transactions',
        id: 'PhonepeQRCode',
        icon: 'fas fa-qrcode',
        route: '/' + RouteUrl.phonepeQRcodeReport,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'USER MANAGEMENT',
    id: 'USER',
    icon: 'fas fa-users menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Users',
        id: 'Users',
        icon: 'fas fa-users',
        route: '/' + RouteUrl.users,
        menuChildren: [],
      },
      {
        displayName: 'RFID Tags',
        id: 'RFIDTags',
        icon: 'fas fa-address-card',
        route: '/' + RouteUrl.rfidTags,
        menuChildren: [],
      },
      {
        displayName: 'CRM Users',
        id: 'CRMUsers',
        icon: 'fas fa-universal-access',
        route: '/' + RouteUrl.admins,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Refund',
        id: 'WalletSettlements',
        icon: 'pi pi-wallet',
        route: '/' + RouteUrl.walletSettlement,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'DISTRIBUTION LIST',
    id: 'DISTRIBUTION',
    icon: 'fa fa-list-alt menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Distribution Channels',
        id: 'DistributionChannels',
        icon: 'fas fa-users',
        route: '/' + RouteUrl.channel,
        menuChildren: [],
      },

      {
        displayName: 'Cost Distribution',
        id: 'CostDistribution',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.softwareCost,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Company Detail',
        id: 'CompanyDetail',
        icon: 'fas fa-industry',
        route: '/' + RouteUrl.clientDetail,
        menuChildren: [],
      },
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
      {
        displayName: 'Firmware Details',
        icon: 'fas fa-cogs',
        id: 'FirmwareDetails',
        route: '/' + RouteUrl.firmwareDetails,
        menuChildren: []
      },
      {
        displayName: 'Payment Processor',
        id: 'PaymentProcessor',
        icon: 'fab fa-cc-mastercard',
        route: '/' + RouteUrl.paymentProcessor,
        menuChildren: [],
      },
      {
        displayName: 'Push Notification',
        id: 'PushNotification',
        icon: 'fa fa-bell',
        route: '/' + RouteUrl.pushNotification,
        menuChildren: [],
      },
      {
        displayName: 'Custom Push Notification',
        id: 'CustomPushNotification',
        icon: 'fa fa-bell',
        route: '/' + RouteUrl.customPushNotification,
        menuChildren: [],
      },
      {
        displayName: 'FAQs',
        id: 'FAQs',
        icon: 'fa fa-question-circle',
        route: 'faq',
        menuChildren: [],
      },
      {
        displayName: 'Amenities',
        id: 'Amenities',
        icon: 'fa fa-map-marker-alt',
        route: 'amenity',
        menuChildren: [],
      },
      {
        displayName: 'SMS Template',
        id: 'SMSTemplate',
        icon: 'fa fa-envelope',
        route: 'sms-template',
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Reports',
        id: 'Reports',
        icon: 'fas fa-chart-line',
        route: '/' + RouteUrl.reports,
        menuChildren: [],
      },
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Software Charges Revenue',
        id: 'SoftwareChargesRevenue',
        icon: 'fa fa-receipt',
        route: '/' + RouteUrl.shareReports,
        menuChildren: [],
      },
      {
        displayName: 'Service Charges Revenue',
        id: 'ServiceChargesRevenue',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.serviceCostReport,
        menuChildren: [],
      },
      {
        displayName: 'Session Charges Revenue',
        id: 'SessionChargesRevenue',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionRevenueReport,
        menuChildren: [],
      },
      {
        displayName: 'Energy Consumption',
        id: 'EngeryConsumption',
        icon: 'fas fa-radiation',
        route: '/' + RouteUrl.energyConsumptionReport,
        menuChildren: [],
      },
      {
        displayName: 'Charge Point Uptime',
        id: 'ChargePointUptime',
        icon: 'fas fa-hourglass-half',
        route: '/' + RouteUrl.chargePointUptimeReport,
        menuChildren: [],
      },
      {
        displayName: 'Charging Duration',
        id: 'ChargingDuration',
        icon: 'fas fa-clock',
        route: '/' + RouteUrl.chargePointDurationReport,
        menuChildren: [],
      },

      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },
      {
        displayName: 'Charge Point Events',
        id: 'EventsReport',
        icon: 'fas fa-snowflake',
        route: '/' + RouteUrl.chargePointEventsReport,
        menuChildren: []
      }
    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-user-times',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'OCPI',
    id: 'ocpi',
    icon: 'fa fa-file menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'EMSPs',
        id: 'EMSPs',
        icon: 'fas fa-book',
        route: '/' + RouteUrl.EMSP,
        menuChildren: [],
      },
      {
        displayName: 'CPO Versions',
        id: 'CPOVersions',
        icon: 'fas fa-code-branch',
        route: '/' + RouteUrl.CPOVersion,
        menuChildren: [],
      },
      {
        displayName: 'CPO Endpoints',
        id: 'CPOEndpoints',
        icon: 'fas fa-link',
        route: '/' + RouteUrl.CPOEndpoint,
        menuChildren: [],
      },
      {
        displayName: 'CPO Registration',
        id: 'CPORegistration',
        icon: 'fas fa-link',
        route: '/' + RouteUrl.CPORegistration,
        menuChildren: [],
      },
      {
        displayName: 'OCPI Session Report',
        id: 'CPORegistration',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.OcpiReport,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Settlement',
        id: 'EMSPWalletSettlement',
        icon: 'pi pi-wallet',
        route: '/' + RouteUrl.EMSPwalletSettlement,
        menuChildren: [],
      },
    ],
  },
];

export const verdeMobilityAnalystMenu: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Locations',
        id: 'location',
        icon: 'fas fa-map-marked-alt',
        route: '/' + RouteUrl.locations,
        menuChildren: [],
      },
      {
        displayName: 'Zones',
        id: 'zone',
        icon: 'fas fa-map-marker-alt',
        route: '/' + RouteUrl.chargingZones,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
      {
        displayName: 'Reservation Tariffs',
        id: 'ReservationTarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.reservationTariff,
        menuChildren: [],
      },
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Agency',
        id: 'carbonCreditAgency',
        icon: 'fas fa-user-circle',
        route: '/' + RouteUrl.carbonCreditAgency,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Firmware Update',
        id: 'FirmwareUpdates',
        icon: 'fas fa-upload',
        route: '/' + RouteUrl.firmwareUpdateHistory,
        menuChildren: []
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
      {
        displayName: 'Razorpay Transactions',
        id: 'RazorpayTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.razorpayTransaction,
        menuChildren: [],
      },
      {
        displayName: 'Phonepe Transactions',
        id: 'PhonepeTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.phonepeTransaction,
        menuChildren: [],
      },
      {
        displayName: 'Stripe Transactions',
        id: 'StripeTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.stripeTransaction,
        menuChildren: [],
      },

    ],
  },
  {
    displayName: 'USER MANAGEMENT',
    id: 'USER',
    icon: 'fas fa-users menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Users',
        id: 'Users',
        icon: 'fas fa-users',
        route: '/' + RouteUrl.users,
        menuChildren: [],
      },
      {
        displayName: 'RFID Tags',
        id: 'RFIDTags',
        icon: 'fas fa-address-card',
        route: '/' + RouteUrl.rfidTags,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Refund',
        id: 'WalletSettlements',
        icon: 'pi pi-wallet',
        route: '/' + RouteUrl.walletSettlement,
        menuChildren: [],
      }
    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
      {
        displayName: 'Firmware Details',
        icon: 'fas fa-cogs',
        id: 'FirmwareDetails',
        route: '/' + RouteUrl.firmwareDetails,
        menuChildren: []
      },
      {
        displayName: 'FAQs',
        id: 'FAQs',
        icon: 'fa fa-question-circle',
        route: 'faq',
        menuChildren: [],
      },
      {
        displayName: 'Amenities',
        id: 'Amenities',
        icon: 'fa fa-map-marker-alt',
        route: 'amenity',
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Reports',
        id: 'Reports',
        icon: 'fas fa-chart-line',
        route: '/' + RouteUrl.reports,
        menuChildren: [],
      },
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Energy Consumption',
        id: 'EngeryConsumption',
        icon: 'fas fa-radiation',
        route: '/' + RouteUrl.energyConsumptionReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
];

export const operatorWithTariff: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Locations',
        id: 'location',
        icon: 'fas fa-map-marked-alt',
        route: '/' + RouteUrl.locations,
        menuChildren: [],
      },
      {
        displayName: 'Zones',
        id: 'zone',
        icon: 'fas fa-map-marker-alt',
        route: '/' + RouteUrl.chargingZones,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
      {
        displayName: 'Reservation Tariffs',
        id: 'ReservationTarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.reservationTariff,
        menuChildren: [],
      },
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Agency',
        id: 'carbonCreditAgency',
        icon: 'fas fa-user-circle',
        route: '/' + RouteUrl.carbonCreditAgency,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
	  {
        displayName: 'Firmware Update',
        id: 'FirmwareUpdates',
        icon: 'fas fa-upload',
        route: '/' + RouteUrl.firmwareUpdateHistory,
        menuChildren: []
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
      {
        displayName: 'Razorpay Transactions',
        id: 'RazorpayTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.razorpayTransaction,
        menuChildren: [],
      },
      {
        displayName: 'Phonepe Transactions',
        id: 'PhonepeTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.phonepeTransaction,
        menuChildren: [],
      },
      {
        displayName: 'Stripe Transactions',
        id: 'StripeTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.stripeTransaction,
        menuChildren: [],
      },

    ],
  },
  {
    displayName: 'USER MANAGEMENT',
    id: 'USER',
    icon: 'fas fa-users menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Users',
        id: 'Users',
        icon: 'fas fa-users',
        route: '/' + RouteUrl.users,
        menuChildren: [],
      },
      {
        displayName: 'RFID Tags',
        id: 'RFIDTags',
        icon: 'fas fa-address-card',
        route: '/' + RouteUrl.rfidTags,
        menuChildren: [],
      },
      {
        displayName: 'CRM Users',
        id: 'CRMUsers',
        icon: 'fas fa-universal-access',
        route: '/' + RouteUrl.admins,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Refund',
        id: 'WalletSettlements',
        icon: 'pi pi-wallet',
        route: '/' + RouteUrl.walletSettlement,
        menuChildren: [],
      }
    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
      {
        displayName: 'Firmware Details',
        icon: 'fas fa-cogs',
        id: 'FirmwareDetails',
        route: '/' + RouteUrl.firmwareDetails,
        menuChildren: []
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [{
      displayName: 'Reports',
      id: 'Reports',
      icon: 'fas fa-chart-line',
      route: '/' + RouteUrl.reports,
      menuChildren: [],
    },
    {
      displayName: 'Session Report',
      id: 'SessionReport',
      icon: 'fas fa-plug',
      route: '/' + RouteUrl.sessionReport,
      menuChildren: [],
    },
    {
      displayName: 'Software Charges Revenue',
      id: 'SoftwareChargesRevenue',
      icon: 'fas fa-receipt',
      route: '/' + RouteUrl.shareReports,
      menuChildren: [],
    },
    {
      displayName: 'Service Charges Revenue',
      id: 'ServiceChargesRevenue',
      icon: 'fas fa-bolt',
      route: '/' + RouteUrl.serviceCostReport,
      menuChildren: [],
    },
    {
      displayName: 'Session Charges Revenue',
      id: 'SessionChargesRevenue',
      icon: 'fas fa-plug',
      route: '/' + RouteUrl.sessionRevenueReport,
      menuChildren: [],
    },
    {
      displayName: 'Carbon Credit Report',
      id: 'CarbonCredit',
      icon: 'fas fa-gift',
      route: '/' + RouteUrl.ccReport,
      menuChildren: [],
    }
    ],
  },
];

export const operatorWithOutTariff: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Locations',
        id: 'location',
        icon: 'fas fa-map-marked-alt',
        route: '/' + RouteUrl.locations,
        menuChildren: [],
      },
      {
        displayName: 'Zones',
        id: 'zone',
        icon: 'fas fa-map-marker-alt',
        route: '/' + RouteUrl.chargingZones,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
      {
        displayName: 'Reservation Tariffs',
        id: 'ReservationTarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.reservationTariff,
        menuChildren: [],
      },
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Agency',
        id: 'carbonCreditAgency',
        icon: 'fas fa-user-circle',
        route: '/' + RouteUrl.carbonCreditAgency,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Firmware Update',
        id: 'FirmwareUpdates',
        icon: 'fas fa-upload',
        route: '/' + RouteUrl.firmwareUpdateHistory,
        menuChildren: []
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
      {
        displayName: 'Razorpay Transactions',
        id: 'RazorpayTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.razorpayTransaction,
        menuChildren: [],
      },
      {
        displayName: 'Phonepe Transaction',
        id: 'PhonepeTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.phonepeTransaction,
        menuChildren: [],
      },
      {
        displayName: 'Stripe Transactions',
        id: 'StripeTransaction',
        icon: 'fas fa-credit-card',
        route: '/' + RouteUrl.stripeTransaction,
        menuChildren: [],
      },

    ],
  },
  {
    displayName: 'USER MANAGEMENT',
    id: 'USER',
    icon: 'fas fa-users menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Users',
        id: 'Users',
        icon: 'fas fa-users',
        route: '/' + RouteUrl.users,
        menuChildren: [],
      },
      {
        displayName: 'RFID Tags',
        id: 'RFIDTags',
        icon: 'fas fa-address-card',
        route: '/' + RouteUrl.rfidTags,
        menuChildren: [],
      },
      {
        displayName: 'CRM Users',
        id: 'CRMUsers',
        icon: 'fas fa-universal-access',
        route: '/' + RouteUrl.admins,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Refund',
        id: 'WalletSettlements',
        icon: 'pi pi-wallet',
        route: '/' + RouteUrl.walletSettlement,
        menuChildren: [],
      }
    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
      {
        displayName: 'Firmware Details',
        icon: 'fas fa-cogs',
        id: 'FirmwareDetails',
        route: '/' + RouteUrl.firmwareDetails,
        menuChildren: []
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Reports',
        id: 'Reports',
        icon: 'fas fa-chart-line',
        route: '/' + RouteUrl.reports,
        menuChildren: [],
      },
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Software Charges Revenue',
        id: 'SoftwareChargesRevenue',
        icon: 'fas fa-receipt',
        route: '/' + RouteUrl.shareReports,
        menuChildren: [],
      },
      {
        displayName: 'Service Charges Revenue',
        id: 'ServiceChargesRevenue',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.serviceCostReport,
        menuChildren: [],
      },
      {
        displayName: 'Session Charges Revenue',
        id: 'SessionChargesRevenue',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionRevenueReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },
    ],
  },

];

export const landOwnerAgencyAnalyst: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
    ],
  },

  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },

    ],
  },

  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
];

export const landOwnerAnalyst: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },

    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
];

export const msfAnalystMenu: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },

    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
];
export const sfAnalystMenu: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },

    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
];
export const distAnalystMenu: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },

    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
];
export const dealerAnalystMenu: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },

    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
];
export const operatorMenu: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'USER MANAGEMENT',
    id: 'USER',
    icon: 'fas fa-users menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'CRM Users',
        id: 'CRMUsers',
        icon: 'fas fa-universal-access',
        route: '/' + RouteUrl.admins,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
];
export const loaOperatorMenu: BaseMenu[] = [
  {
    displayName: 'Dashboard',
    id: 'dash',
    icon: 'fas fa-home menu-icon',
    route: '/' + RouteUrl.dashboard,
    menuChildren: [],
  },
  {
    displayName: 'ASSETS',
    id: 'asset',
    icon: 'fa fa-cubes menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Charge Points',
        id: 'ChargePoints',
        icon: 'fas fa-charging-station',
        route: '/' + RouteUrl.chargePoints,
        menuChildren: [],
      },
      {
        displayName: 'Charging Tariffs',
        id: 'chargingtarrifs',
        icon: 'fas fa-tags',
        route: '/' + RouteUrl.tariffs,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'HISTORY',
    id: 'histori',
    icon: 'fa fa-history menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Authorizations',
        id: 'Authorizations',
        icon: 'fas fa-lock',
        route: '/' + RouteUrl.authorizations,
        menuChildren: [],
      },
      {
        displayName: 'Reservations',
        id: 'Reservation',
        icon: 'fas fa-ticket-alt fa-fw text-white',
        route: '/' + RouteUrl.reservation,
        menuChildren: [],
      },
      {
        displayName: 'Charging Sessions',
        id: 'ChargingSessions',
        icon: 'fas fa-bolt',
        route: '/' + RouteUrl.chargingSessions,
        menuChildren: [],
      },
      {
        displayName: 'Wallet Transactions',
        id: 'Transaction',
        icon: 'fas fa-exchange-alt',
        route: '/' + RouteUrl.transaction,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'USER MANAGEMENT',
    id: 'USER',
    icon: 'fas fa-users menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'CRM Users',
        id: 'CRMUsers',
        icon: 'fas fa-universal-access',
        route: '/' + RouteUrl.admins,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'CONFIGURATION',
    id: 'confi',
    icon: 'fa fa-cogs menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Change Password',
        id: 'ChangePassword',
        icon: 'fa fa-user-secret',
        route: '/' + RouteUrl.changePassword,
        menuChildren: [],
      },
    ],
  },
  {
    displayName: 'ANALYTICS',
    id: 'analytic',
    icon: 'fas fa-chart-line menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Session Report',
        id: 'SessionReport',
        icon: 'fas fa-plug',
        route: '/' + RouteUrl.sessionReport,
        menuChildren: [],
      },
      {
        displayName: 'Carbon Credit Report',
        id: 'CarbonCredit',
        icon: 'fas fa-gift',
        route: '/' + RouteUrl.ccReport,
        menuChildren: [],
      },

    ],
  },
  {
    displayName: 'REFERRAL COUPON',
    id: 'REFERRAL',
    icon: 'fas fa-handshake menu-icon',
    route: null,
    menuChildren: [
      {
        displayName: 'Pending Requests',
        id: 'PendingRequests',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.coupanCode,
        menuChildren: [],
      },
      {
        displayName: 'Approved Request',
        id: 'ApprovedRequest',
        icon: 'fas fa-handshake',
        route: '/' + RouteUrl.approvedCoupanCode,
        menuChildren: [],
      },
    ],
  },
];


export const chargingPointActivityTabHeader: string[] = ['Charging Sessions', 'Reservations', 'Authorizations', 'Charging Profiles', 'Share Requests'];

export const chargingPointLogsTabHeader: string[] = ['Communication Logs', 'Communication Log Details', 'Change History Logs'];
export const snapshotTarrifTabHeader: string[] = ['Session Snapshot', 'Tarrif Snapshot'];
export const chargingPointLogsWithRTTYTabHeader: string[] = ['Communication Logs', 'Communication Log Details', 'RTTY', 'Change History Logs'];
export const chargingSessionLogsTabHeader: string[] = ['Communication Logs', 'Communication Log Details'];


export const chargingPointCreateTabHeader: string[] = ['General', 'OCPP', 'EVSEs', 'Advanced', 'Partner'];

export const crmUserTypesTabHeader: string[] = ['General', 'RFID Tags'];

export const chargePointDetailsTabHeader: string[] = ['Charging Sessions', 'Transactions', 'Authorizations', 'Reservations', 'Actions', 'Wallet Transactions', 'Razorpay Transactions'];

export const userDetailsTabHeader: string[] = ['Charging Sessions', 'Authorizations', 'Wallet Transactions', 'Razorpay Transactions', 'Phonepe Transactions', 'Stripe Transactions', 'Settlement', 'Reservations'];

export const emspDetailsTabHeader: string[] = ['Charge Points', 'Tariff', 'Version & EndPoints', 'Charging Sessions', 'Token'];

export const rfidTabHeader: string[] = ['RFID Tags', 'User Tags', 'Vehicle Model'];

export const state: string[] = ['Active', 'Inactive'];

export const locationDetailsTabHeader: string[] = ['General', 'Address', 'Charging Zones'];

export const locationCreateTabHeader: string[] = ['General', 'Address'];

export const chargingPointDetailTabHeader: string[] = ['General', 'OCPP', 'EVSEs', 'Hardware', 'Configuration', 'Reservations', 'Distribution Channel', 'Amenities', 'eMSP', 'Other Details'];

export const vehicleCompanyDetailsTabHeader: string[] = ['General', 'Vehicle Model'];

export enum LocalStorageKeys {
  activeAlarms = 'active_alarms',
  tempData = 'tempdata',
  loginResponse = 'loginResponseVM',
  tagForChart = 'tag_for_chart',
  CPOLoginResponse = 'CPOloginResponseVM'
}

export enum MQTTTopics {

}

export enum enumCrmUserTypesTabHeader {
  General = 'General',
  RFIDTags = 'RFID Tags'
}

export enum enumChargePointDetailsTabHeader {
  ChargingSessions = 'Charging Sessions',
  Transactions = 'Transactions',
  Authorizations = 'Authorizations',
  Reservations = 'Reservations',
  Actions = 'Actions',
  WalletTransactions = 'Wallet Transactions',
  RazorpayTransaction = 'Razorpay Transactions'
}

export enum ENUMUserDetailsTabHeader {
  ChargingSessions = 'Charging Sessions',
  Transactions = 'Transactions',
  Authorizations = 'Authorizations',
  WalletTransactions = 'Wallet Transactions',
  RazorpayTransaction = 'Razorpay Transactions',
  Settlement = 'Settlement',
  Reservation = 'Reservations',
  PhonepeTransaction = 'Phonepe Transactions',
  StripeTransaction = 'Stripe Transactions'
}
export enum enumEMSPDetailsTabHeader {
  ChargePoint = 'Charge Points',
  Tariff = 'Tariff',
  Version = 'Version & EndPoints',
  ChargingSessions = 'Charging Sessions',
  Token = 'Token'
}

export enum enumUserDetailsTagTabHeader {
  RFIDTags = 'RFID Tags',
  UserTags = 'User Tags',
  VehicleTags = 'Vehicle Model'
}
export enum enumPartnerContractTabHeader {
  Settings = 'Settings',
  AccessPermission = 'Access and Permission',
  RevenuSharing = 'Revenue Sharing',
  MonthlyPlatfromFee = 'Monthly Platfrom Fee',
}

export const partnerContractTabHeader: string[] = ['Settings', 'Revenue Sharing', 'Monthly Platfrom Fee'];

export enum enumTeriffTabHeader {
  General = 'General',
  Connection = 'Connection Fees',
  Consumption = 'Consumption Fees',
  Parking = 'Parking Slot',
}

export const tariffTabHeader: string[] = ['General', 'Connection Fees', 'Consumption Fees', 'Parking Slot'];
export const freeTariffTabHeader: string[] = ['General'];

export enum enumClientTabHeader {
  General = 'General',
  OCPP = 'OCPP',
  SMTP = 'SMTP',
  SMS = 'OTP Template',
  PushNotify = 'Push Notification'
}

export const clientTabHeader: string[] = ['General', 'OCPP', 'SMTP', 'OTP Template', 'Push Notification'];
export const clientDetailsTabHeader: string[] = ['General', 'SMTP'];

export enum dashboardTileButton {
  AC = 'AC',
  DC = 'DC',
  ALL = 'ALL'
}

export enum DasboardRange {
  DAYS_90 = '90',
  MONTH_TO_DATE = 'MTD',
  QUARTER_TO_DATE = 'QTD',
  YEAR_TO_DATE = 'YTD',
  TODAY = 'TODAY',
  DAYS_30 = '30',
  DAYS_60 = '60',
  DAYS_365 = '365',
  LAST_FINACIAL_YEAR = 'LFY',
  THIS_FINACIAL_YEAR = 'TFY',
  CUSTOM = 'CUSTOM',

}

export const dasboardRangeValues: ValueDisplayString[] = [

  {
    display: 'Month To Date',
    value: DasboardRange.MONTH_TO_DATE
  },
  {
    display: 'Quarter To Date',
    value: DasboardRange.QUARTER_TO_DATE
  },
  {
    display: 'Year To Date',
    value: DasboardRange.YEAR_TO_DATE
  },
  {
    display: 'Today',
    value: DasboardRange.TODAY
  },
  {
    display: '30 Days',
    value: DasboardRange.DAYS_30
  },
  {
    display: '60 Days',
    value: DasboardRange.DAYS_60
  },
  {
    display: '90 Days',
    value: DasboardRange.DAYS_90
  },
  {
    display: '365 Days',
    value: DasboardRange.DAYS_365
  },

];


export enum UrlParameter {
  onlyEnabled = 'onlyEnabled',
  clientId = 'clientId',
  roleId = 'roleId',
  userId = 'userId',
  siteId = 'siteId',
  floorId = 'floorId',
  roomId = 'roomId',
  deviceTypeCode = 'deviceTypeCode',
  deviceTypeId = 'deviceTypeId',
  forAlarm = 'forAlarm',
  deviceId = 'deviceId',
  isIncludeFunction = 'isIncludeFunction',
  alarmId = 'alarmId',
  tagId = 'tagId',
  schedulerId = 'schedulerId',
  tankId = 'tankId',
  tankTypeDetailId = 'tankTypeDetailId',
  ipAddress = 'ipAddress',
  locimageId = 'chargePointLocationImageId',
  chargingZoneId = 'chargingZoneId',
  chargingZoneStatus = 'status',
  authorizationId = 'authorizationId'
}

export enum ErrorCode {
  badRequest = 400,
  unauthorizedRequest = 401,
  accessDenied = 403,
  internalServer = 500,
  notfound = 404,
  sessionExpired = 440
}

export enum ErrorMessage {
  invalidRefreshToken = 'Invalid refresh token',
  sessionExpired = 'Session expired. Please log in again'
}

export enum ToastMessages {
  MESSAGE_API_RESPONSE_ERROR = 'Error in API Response',
}

export enum Browser {
  InternetExplorer = 'Microsoft Internet Explorer',
  Opera = 'Opera',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Firefox = 'Firefox',
  Version = 'Version'
}

export enum BrowserVersion {
  Chrome = 76,
  Firefox = 59
}

export enum TankType {
  Round = 'Round'
}

export enum Permission {
  Monitor = 'Monitor'
}

export enum LocationDetailTab {
  general = 'General',
  address = 'Address',
  chargingZones = 'Charging Zones'
}

export enum VehicleCompanyDetailTab {
  general = 'General',
  vehicleModel = 'Vehicle Model'
}

export enum Phases {
  singlePhase = 'Single Phase',
  singlePhaseValue = 'EVSE_PHASES_SINGLE_PHASE',
  phase3 = '3-Phase',
  phase3Value = 'EVSE_PHASES_3_PHASE'
}
export enum Processor {
  phonePe = 'PAYMENT_PROCESSOR_PHONEPE',
  razorPay = 'PAYMENT_PROCESSOR_RAZORPAY',
  phonepeQRCode = 'PAYMENT_PROCESSOR_PHONEPE_QRCODE',
  stripePayment = 'PAYMENT_PROCESSOR_STRIPE'
}
export enum ProcessorType {
  phonePe = 'PhonePe',
  razorPay = 'Razorpay',
  Stripe = 'Stripe'
}
export enum EnumPhasesRotation {
  TSR = 'EVSE_PHASE_ROTATION_TSR',
  RTS = 'EVSE_PHASE_ROTATION_RTS',
  SRT = 'EVSE_PHASE_ROTATION_SRT',
  STR = 'EVSE_PHASE_ROTATION_STR',
  TRS = 'EVSE_PHASE_ROTATION_TRS',
  RST = 'EVSE_PHASE_ROTATION_RST',
  L1 = 'EVSE_CONNECTED_PHASE_L1',
  L2 = 'EVSE_CONNECTED_PHASE_L2',
  L3 = 'EVSE_CONNECTED_PHASE_L3'
}

export enum ChargingPointDetailTab {
  general = 'General',
  ocpp = 'OCPP',
  evse = 'EVSEs',
  hardware = 'Hardware',
  configuration = 'Configuration',
  advanced = 'Advanced',
  distributionChannel = 'Distribution Channel',
  reservation = 'Reservation',
  amenities = 'Amenities',
  eMsp = 'eMSP',
  otherDetails = 'Other Details'

}


export enum ChargingPointActivityTab {
  chargingSessions = 'Charging Sessions',
  transactions = 'Transactions',
  reservations = 'Reservations',
  hardware = 'Hardware',
  authorizations = 'Authorizations',
  chargingProfiles = 'Charging Profiles',
  shareRequests = 'Share Requests'
}

export enum ChargingPointLogsTab {
  actions = 'Actions',
  hWLogs = 'Hardware Status Logs',
  nwtworkLogs = 'Network Status Log',
  comLogs = 'Communication Logs',
  diagRequests = 'Diagnostic Requests',
  ReqResLogs = 'Request Response Logs',
  DetailsLogs = 'Communication Log Details',
  Rtty = 'RTTY',
  History = 'Change History Logs'
}
export enum ChargingSessionLogsTab {
  comLogs = 'Communication Logs',
  ReqResLogs = 'Request Response Logs',
  DetailsLogs = 'Communication Logs Details'
}
export enum EvseLogsTab {
  comLogs = 'Communication Logs',
  ReqResLogs = 'Reservations',
  DetailsLogs = 'Communication Logs Details',
  ChargingProfile = 'Charging Profile'
}

export enum ChargingPointStatus {
  Active = 'CHARGE_POINT_STATUS_ACTIVE',
  Disabled = 'CHARGE_POINT_STATUS_DISABLED',
  Demo = 'CHARGE_POINT_STATUS_DEMO',
  OutofOrder = 'CHARGE_POINT_STATUS_OUT_OF_ORDER'
}

export enum RfidStatus {
  Active = 'RFID_STATUS_ACTIVE',
  Disabled = 'RFID_STATUS_DISABLED',
  Expired = 'RFID_STATUS_EXPIRED',
  Suspended = 'RFID_STATUS_SUSPENDED'
}

export enum OrderStatus {
  Paid = 'paid',
  Created = 'created',
  Attempted = 'attempted',
  Failed='failed'
}

export enum PhonepeOrderStatus {
  Success = 'Success',
  Initiated = 'Initiated',
  Declined = 'Declined',
  Pending = 'Pending',
  Failed = 'Failed',
  TimedOut = 'TimedOut',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  INITIATED = 'INITIATED',
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
}

export enum StripeOrderStatus {
  Paid = 'paid',
  Unpaid = 'unpaid',
  Open = 'open'
}

export enum PaymentStatus {
  Captured = 'captured',
  Created = 'created',
  Authorized = 'authorized',
  Refunded = 'refunded',
  Failed = 'failed'
}
export enum RfidErrors {
  TAG_IS_NULL = 'TagId is null',
  EXPIRYDATE_IS_NULL = 'Expiration date is null',
  STATUS_IS_NULL = 'Status is null',
  USEREMAIL_IS_NULL = 'User email is null',
  STATUS_NOT_VALID = 'Status is not valid',
  EXPIRYDATE_NOT_VALID = 'Expiration date is less then or equal to current date',
  TAG_IS_DUPLICATE = 'TagId is duplicate',
}

// export class EVSEStatus {
//   Active = 'EVSE_STATUS_ACTIVE';
//   OutOfOrder = 'EVSE_STATUS_OUT_OF_ORDER';
//   Disabled = 'EVSE_STATUS_DISABLED';
// }

export enum ChargingPointActions {
  reset = 'Reset',
  updateFirmware = 'Update Firmware',
  compSchedule = 'Get Composite Schedule',
  getDiagnostics = 'Get diagnostics',
  triggerMessage = 'Trigger Message',
  clearCache = 'Clear Cache',
  raiseSecProfile = 'Raise security profile',
  resetSecProfile = 'Reset security profile',
  dataTransfer = 'Data Transfer',
  setChargingProfile = 'Set Charging Profile',
  clearChargingProfile = 'Clear Charging Profile',

}

export const chargingPointActionsList: ActionModel[] = [
  { actionName: ChargingPointActions.reset },
  { actionName: ChargingPointActions.updateFirmware },
  { actionName: ChargingPointActions.triggerMessage },
  { actionName: ChargingPointActions.dataTransfer },
  { actionName: ChargingPointActions.clearCache },
  { actionName: ChargingPointActions.setChargingProfile },
  { actionName: ChargingPointActions.clearChargingProfile },
];

export enum CPHardwareStatus {
  online = 'CHARGE_POINT_PRESENCE_STATUS_ONLINE',
  offline = 'CHARGE_POINT_PRESENCE_STATUS_OFFLINE',
  onlineValue = 'Online',
  offlineValue = 'Offline',
}

export enum CPConnectionStatus {
  available = 'CONNECTION_STATUS_AVAILABLE',
  unavailable = 'CONNECTION_STATUS_UNAVAILABLE',
  longTermUnavailable = 'CONNECTION_STATUS_LONG_TERM_UNAVAILABLE',
  availableColor = 'success',
  unavailableColor = 'secondary',
  // longTermUnavailableColor = 'secondary',
}

export enum EVSEHardwareStatus {
  IMPLEMENTED = 'CP_ACTION_STATUS_NOT_IMPLEMENTED',
  REQUESTED = 'CP_ACTION_STATUS_REQUESTED',
  REJECTED = 'CP_ACTION_STATUS_REJECTED',
  CHARGING = 'CP_ACTION_STATUS_CHARGING',
  PREPARING = 'CP_ACTION_STATUS_PREPARING',
  NOT_SUPPORTED = 'CP_ACTION_STATUS_NOT_SUPPORTED',
  ACCEPTED = 'CP_ACTION_STATUS_ACCEPTED',
  FAILED = 'CP_ACTION_STATUS_FAILED',
  FINISHING = 'CP_ACTION_STATUS_FINISHING',
  AVAILABLE = 'CP_ACTION_STATUS_AVAILABLE',
  SUSPENDED_EVSE = 'CP_ACTION_STATUS_SUSPENDED_EVSE',
  UNAVAILABLE = 'CP_ACTION_STATUS_UNAVAILABLE'
}

export enum CHARGING_SESSIONS_STATUS {
  CHARGING = 'CHARGING_SESSIONS_STATUS_CHARGING',
  AWAITING = 'CHARGING_SESSIONS_STATUS_AWAITING',
  AWAITING_OTP = 'CHARGING_SESSIONS_STATUS_AWAITING_OTP'
}

export enum EVSEActionValue {
  startSession = 'START_SESSION',
  startSessionValue = 'Start Session',
  stopSession = 'STOP_SESSION',
  stopSessionValue = 'Stop Session',
  triggerMessage = 'TRIGGER_MESSAGE',
  triggerMessageValue = 'Trigger Message',
  exportCSV = 'EXPORT_CSV',
  reservation = 'RESERVATION',
  changeAvailability = 'CHANGE_AVAILABILITY',
  changeAvailabilityValue = 'Change Availability',
  cancelReservation = 'Cancel Reservation',
  setChargingProfile = 'Set Charging Profile',
  clearChargingProfile = 'Clear Charging Profile',
  unlockConnector = 'Unlock Connector',
}

export const changeAvailabilityValue: ValueDisplayString[] = [
  { display: 'Inoperative', value: 'INOPERATIVE' },
  { display: 'Operative', value: 'OPERATIVE' }
];

export enum TariffTypes {
  flateRate = 'TARIFF_TYPE_FLATE_RATE',
  free = 'TARIFF_TYPE_FREE',
  durationWithEnergy = 'TARIFF_TYPE_DURATION_ENERGY'
}

export enum UserStatus {
  active = 'Active',
  disabled = 'Disabled'
}

export enum RevenueChartLabels {
  paidAmount = 'Paid Amount',
  outstandingAmount = 'Outstanding Amount'
}

export enum ReportMatrix {
  revenuePaid = 'REVENUE_PAID',
}

export const evseActionsListWithStart: ActionListModel[] = [
  { display: 'Start Session', value: EVSEActionValue.startSession, isEnable: false },
  { display: 'Trigger Message', value: EVSEActionValue.triggerMessage, isEnable: false },
  { display: 'Reservation', value: EVSEActionValue.reservation, isEnable: true },
  { display: 'Change Availability', value: EVSEActionValue.changeAvailability, isEnable: false },
  { display: 'Set Charging Profile', value: EVSEActionValue.setChargingProfile, isEnable: false },
  { display: 'Clear Charging Profile', value: EVSEActionValue.clearChargingProfile, isEnable: false },
  { display: 'Unlock Connector', value: EVSEActionValue.unlockConnector, isEnable: true }];



export const evseActionsListWithStop: ActionListModel[] = [
  { display: 'Stop Session', value: EVSEActionValue.stopSession, isEnable: false },
  { display: 'Trigger Message', value: EVSEActionValue.triggerMessage, isEnable: false },
  { display: 'Reservation', value: EVSEActionValue.reservation, isEnable: true },
  { display: 'Change Availability', value: EVSEActionValue.changeAvailability, isEnable: false },
  { display: 'Set Charging Profile', value: EVSEActionValue.setChargingProfile, isEnable: false },
  { display: 'Clear Charging Profile', value: EVSEActionValue.clearChargingProfile, isEnable: false },
  { display: 'Unlock Connector', value: EVSEActionValue.unlockConnector, isEnable: true }];

export const sessionActionsListWithStop: ValueDisplayString[] = [
  { display: 'Stop Session', value: EVSEActionValue.stopSession },
  { display: 'Export CSV', value: EVSEActionValue.exportCSV }
];
export const sessionActionsListWithoutStop: ValueDisplayString[] = [
  { display: 'Export CSV', value: EVSEActionValue.exportCSV }
];

export enum AuthorizationsMethods {
  DEVICE_TYPE_RFID_TAG = 'DEVICE_TYPE_RFID_TAG',
  DEVICE_TYPE_MOBILE_DEVICE = 'DEVICE_TYPE_MOBILE_DEVICE',
  DEVICE_TYPE_ADMIN_ACCOUNT = 'DEVICE_TYPE_ADMIN_ACCOUNT',
  DEVICE_TYPE_EMSP = 'DEVICE_TYPE_EMSP',
  DEVICE_TYPE_QRCODE = 'DEVICE_TYPE_QRCODE',
  DEVICE_TYPE_OTP = 'DEVICE_TYPE_OTP',
  DEVICE_TYPE_USER_TAG = 'DEVICE_TYPE_USER_TAG',
  DEVICE_TYPE_UNKNOWN_TAG = 'DEVICE_TYPE_UNKNOWN_TAG',
  DEVICE_TYPE_MAC_TAG = 'DEVICE_TYPE_MAC_TAG'

}
export enum AuthorizationsMethodTypesDisplay {
  DEVICE_TYPE_RFID_TAG = 'RFID TAG',
  DEVICE_TYPE_ADMIN_ACCOUNT = 'ADMIN ACCOUNT',
  DEVICE_TYPE_MOBILE_DEVICE = 'MOBILE DEVICE',
  DEVICE_TYPE_EMSP = 'EMSP ACCOUNT',
  DEVICE_TYPE_QRCODE = 'QR CODE',
  DEVICE_TYPE_OTP = 'OTP',
  DEVICE_TYPE_USER_TAG = 'USER TAG',
  DEVICE_TYPE_UNKNOWN_TAG = 'UNKNOWN TAG',
  DEVICE_TYPE_MAC_TAG = 'AUTO CHARGE',
  DEVICE_TYPE_EMSP_ACCOUNT = 'EMSP Account',

}
export enum MQTTMessageTypes {
  startTransaction = 'COMMAND_LIST_START_TRANSACTION',
  stopTransaction = 'COMMAND_LIST_STOP_TRANSACTION',
  remoteStopTransaction = 'COMMAND_LIST_REMOTE_STOP_TRANSACTION',
  statusNotification = 'STATUS_NOTIFICATION',
  meterValues = 'METER_VALUES',
  chargePointPresence = 'CHARGE_POINT_PRESENCE',
  chargePointHeartbeat = 'COMMAND_LIST_HEARTBEAT',
}

export enum MQTTMessageLogTypes {
  logTypeNetwork = 'LOG_TYPE_NETWORK',
  logTypeHardware = 'LOG_TYPE_HARDWARE',
}
export enum RequestResponseMessageType {
  Request = '2',
  Response = '3',
  Error = '4'
}

export enum ChannelValues {
  Vm = 'VM',
  Msf = 'MSF',
  Sf = 'SF',
  Dist = 'DIST',
  Del = 'DEL',
  Loa = 'LOA',
  Lo = 'LO'
}
export enum ChanneNames {
  Vm = 'Verde Mobility',
  Msf = 'Master State Franchise',
  Sf = 'State Franchise',
  Dist = 'Distributor',
  Del = 'Dealer',
  Loa = 'Others',
  Lo = 'LandOwner',
  La = 'Liaisoning Agency'
}
export const channelType: ChannelTypeModel[] = [
  { name: ChanneNames.Msf, value: ChannelValues.Msf, parent: [{ id: ChannelValues.Vm, name: ChanneNames.Vm }] },
  { name: ChanneNames.Sf, value: ChannelValues.Sf, parent: [{ id: ChannelValues.Msf, name: ChanneNames.Msf }] },
  { name: ChanneNames.Dist, value: ChannelValues.Dist, parent: [{ id: ChannelValues.Msf, name: ChanneNames.Msf }, { id: ChannelValues.Sf, name: ChanneNames.Sf }] },
  { name: ChanneNames.Del, value: ChannelValues.Del, parent: [{ id: ChannelValues.Msf, name: ChanneNames.Msf }, { id: ChannelValues.Sf, name: ChanneNames.Sf }, { id: ChannelValues.Dist, name: ChanneNames.Dist }] },
  { name: ChanneNames.Loa, value: ChannelValues.Loa, parent: [] }
]

export const channelOperator: NameValue<string>[] = [
  { name: ChanneNames.Msf, value: ChannelValues.Msf },
  { name: ChanneNames.Sf, value: ChannelValues.Sf },
  { name: ChanneNames.Dist, value: ChannelValues.Dist },
  { name: ChanneNames.Del, value: ChannelValues.Del },
  { name: ChanneNames.Loa, value: ChannelValues.Loa },
  // { name: ChanneNames.Lo, value: ChannelValues.Lo }
]
export const chargePointOwner: NameValue<string>[] = [

  { name: ChanneNames.Msf, value: 'CHANNEL_TYPE_MSF' },
  { name: ChanneNames.Sf, value: 'CHANNEL_TYPE_SF' },
  { name: ChanneNames.Dist, value: 'CHANNEL_TYPE_DIST' },
  { name: ChanneNames.Del, value: 'CHANNEL_TYPE_DEL' },
  { name: ChanneNames.La, value: 'CHANNEL_TYPE_LOA' },
  { name: ChanneNames.Lo, value: 'CHANNEL_TYPE_LO' }
]

export const vehicleType: NameValue<string>[] = [
  { name: '2 Wheeler', value: 'VEHICLE_TYPE_2_WHEELER' },
  { name: '4 Wheeler', value: 'VEHICLE_TYPE_4_WHEELER' }
]

export enum ChargePointStatus {
  ChargePointStatusNew = 'CHARGE_POINT_STATUS_NEW',
  ChargePointStatusQcPassed = 'CHARGE_POINT_STATUS_QC_PASSED',
  ChargePointStatusDispatched = 'CHARGE_POINT_STATUS_DISPATCHED',
  ChargePointStatusInstalled = 'CHARGE_POINT_STATUS_INSTALLED',
  ChargePointStatusLive = 'CHARGE_POINT_STATUS_LIVE',
  ChargePointStatusMaintenance = 'CHARGE_POINT_STATUS_MAINTENANCE',
}
export enum ElectricityOccupiedBy {
  Lo = 'ELECTRICITY_OCCUPY_BY_LO',
  Loa = 'ELECTRICITY_OCCUPY_BY_LOA',
  Vm = 'ELECTRICITY_OCCUPY_BY_VM'
}

export enum TariffType {
  TariffTypeFree = 'TARIFF_TYPE_FREE',
  TariffTypDynamic = 'TARIFF_TYPE_DYNAMIC',
  TariffTypeFixed = 'TARIFF_TYPE_FIXED'
}

export enum chargingProfileSetOption {
  profileSetOnConnector = 'CHARGING_PROFILE_SET_OPTION_CPC',
  profileSetOnChargePoint = 'CHARGING_PROFILE_SET_OPTION_C',
  txProfile = 'TX_PROFILE',
}
export enum ChargingProfileKindType {
  absolute = 'ABSOLUTE',
  recurring = 'RECURRING',
  relative = 'RELATIVE',
}
export enum ChargingProfileActionStatus {
  requested = 'SET_PROFILE_STATUS_REQUESTED',
  accepted = 'SET_PROFILE_STATUS_ACCEPTED',
  rejected = 'SET_PROFILE_STATUS_REJECTED',
  unknown = 'SET_PROFILE_STATUS_UNKNOWN',
}
export enum ChargingProfile {
  set = 'SET_PROFILE',
  clear = 'CLEAR_PROFILE'
}

export enum SubscriptionTopic {
  verdemobilityapp = 'verdemobilityapp'
}
export enum DatabaseFields {
  createdDate = 'createdDate',
  startedAt = 'startedAt',
  createdAt = 'createdAt',
  startAt = 'startAt',
  endAt = 'endAt',
  receiptDate = 'receiptDate',
  expirationDate = 'expirationDate',
  validFrom = 'validFrom',
  validTo = 'validTo',
  connectionStatus = 'connectionStatus',
  type = 'type',
  requestStatus = 'requestStatus',
  systemStatusDisplay = 'systemStatusDisplay',
  evsehardwarestatus = 'evsehardwarestatus',
  chargePointId = 'chargePointId',
  sessionStatus = 'sessionStatus',
  isConnectionLost = 'isConnectionLost',
  userId = 'userId',
  chargePointEvseId = 'chargePointEvseId',
  chargePointReservationId = 'chargePointReservationId',
  rfidTagId = 'rfidTagId',
  sessionReceiptId = 'sessionReceiptId',
  emspId = 'emspId',
  chargePointLocationId = 'chargePointLocationId',
  channelId = 'channelId',
  lastHeartbeatAt = 'lastHeartbeatAt',
  chargePointOwnerId = 'chargePointOwnerId',
  agencyName = 'agencyName',
  transactionDate = 'transactionDate',
  requestedAt = 'requestedAt',
  ocpiUserId = 'ocpiUserId',
  methodDisplay = 'methodDisplay',
  startDate = 'startDate',
  endDate = 'endDate',
  retrieve = 'retrieve'
}

export enum NoData {
  noDataFound = 'No Data Found',
}

export const dasboardRevenueChartValues: ValueDisplayString[] = [
  {
    display: 'Quarter To Date',
    value: DasboardRange.QUARTER_TO_DATE
  },
  {
    display: 'Year To Date',
    value: DasboardRange.YEAR_TO_DATE
  },
  {
    display: 'Last Financial Year',
    value: DasboardRange.LAST_FINACIAL_YEAR
  },
  {
    display: 'This Financial Year',
    value: DasboardRange.THIS_FINACIAL_YEAR
  },
  {
    display: 'Custom',
    value: DasboardRange.CUSTOM
  },

];
export enum ReportInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

export enum ReportPeriods {
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM = 'CUSTOM'
}
export enum Month {
  Jan = 'January',
  Feb = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  Aug = 'August',
  Sep = 'September',
  Oct = 'October',
  Nov = 'November',
  Dec = 'December'
}

export const monthList: ValueDisplay[] = [

  { display: Month.Jan, value: 1 },
  { display: Month.Feb, value: 2 },
  { display: Month.March, value: 3 },
  { display: Month.April, value: 4 },
  { display: Month.May, value: 5 },
  { display: Month.June, value: 6 },
  { display: Month.July, value: 7 },
  { display: Month.Aug, value: 8 },
  { display: Month.Sep, value: 9 },
  { display: Month.Oct, value: 10 },
  { display: Month.Nov, value: 11 },
  { display: Month.Dec, value: 12 }
]
export const roleType: NameValue<string>[] = [
  { name: 'SENDER', value: 'SENDER' },
  { name: 'RECEIVER', value: 'RECEIVER' }
]
export const identifier: NameValue<string>[] = [
  { name: 'CDRs', value: 'cdrs' },
  { name: 'Commands', value: 'commands' },
  { name: 'Credentials', value: 'credentials' },
  { name: 'Locations', value: 'locations' },
  { name: 'Sessions', value: 'sessions' },
  { name: 'Tariffs', value: 'tariffs' },
  { name: 'Tokens', value: 'tokens' }

]
export enum ChargerType {
  CompactCharger = 'CHARGER_TYPE_COMPACT',
  ChargerTypeNA = 'CHARGER_TYPE_NA',
}
export enum CompactRequestStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}
export enum SearchInputType {
  Email = 'Email',
  Phone = 'Phone',
}
export enum ReservationType {
  Active = 'active',
  UpComing = 'upcoming',
  History = 'history'
}
export enum ConfigurationType {
  Boolean = 'BOOLEAN',
  String = 'STRING',
  Numeric = 'NUMERIC'
}
export enum ConfigurationSelectedValue {
  MeterValuesSampledData = 'MeterValuesSampledData',
  MeterValuesAlignedData = 'MeterValuesAlignedData'
}

export const configurationKeyValue: ValueDisplayString[] = [
  { display: 'Current.Offered', value: 'Current.Offered' },
  { display: 'Energy.Active.Export.Register', value: 'Energy.Active.Export.Register' },
  { display: 'Energy.Active.Import.Register', value: 'Energy.Active.Import.Register' },
  { display: 'Energy.Reactive.Export.Register', value: 'Energy.Reactive.Export.Register' },
  { display: 'Energy.Reactive.Import.Register', value: 'Energy.Reactive.Import.Register' },
  { display: 'Energy.Active.Export.Interval', value: 'Energy.Active.Export.Interval' },
  { display: 'Energy.Active.Import.Interval', value: 'Energy.Active.Import.Interval' },
  { display: 'Energy.Reactive.Export.Interval', value: 'Energy.Reactive.Export.Interval' },
  { display: 'Energy.Reactive.Import.Interval', value: 'Energy.Reactive.Import.Interval' },
  { display: 'Frequency', value: 'Frequency' },
  { display: 'Power.Active.Export', value: 'Power.Active.Export' },
  { display: 'Power.Active.Import', value: 'Power.Active.Import' },
  { display: 'Power.Factor', value: 'Power.Factor' },
  { display: 'Power.Offered', value: 'Power.Offered' },
  { display: 'Power.Reactive.Export', value: 'Power.Reactive.Export' },
  { display: 'Power.Reactive.Import', value: 'Power.Reactive.Import' },
  { display: 'RPM', value: 'RPM' },
  { display: 'SoC', value: 'SoC' },
  { display: 'Temperature', value: 'Temperature' },
  { display: 'Voltage', value: 'Voltage' }
];
export enum Measurand {
  CurrentImport = "CURRENT_IMPORT",
  Temperature = "TEMPERATURE",
  Voltage = "VOLTAGE",
  MeterValue = "ENERGY_ACTIVE_IMPORT_REGISTER"
}
export enum ChargePointTypeEnum {
  AC = 'EVSE_TYPE_AC',
  DC = 'EVSE_TYPE_DC'
}
export const chargePointType: ValueDisplayString[] = [
  { display: 'AC', value: 'EVSE_TYPE_AC' },
  { display: 'DC', value: 'EVSE_TYPE_DC' }
];
export const firmwareACTypeData : ValueDisplayString[] = [
  { display: 'ESP', value: 'AC_ESP' },
  { display: 'ST', value: 'AC_ST' }
];
export const firmwareDCTypeData : ValueDisplayString[] = [
  { display: 'MASTER BOARD', value: 'DC_MASTER_BOARD' },
  { display: 'SLAVE BOARD', value: 'DC_SLAVE_BOARD' },
  { display: 'LCD', value: 'DC_LCD' },
]; 
export const reportType = {
  DisconnectedReconnectedCount:'Disconnected & Reconnected Count',
  ErrorEncounteredCount:'Error Encountered Count',
  PreparingToChargingCount:'Preparing to Charging or Not Charging Count',
  SessionStoppedWithOrWithoutFailure:'Session stopped with v/s without failure'
}
export const reportTypeValues: ValueDisplayString[] = [
  { display: 'Disconnected & Reconnected Count', value: 'Disconnected & Reconnected Count' },
  { display: 'Error Encountered Count', value: 'Error Encountered Count' },
  { display: 'Preparing to Charging or Not Charging Count', value: 'Preparing to Charging or Not Charging Count' },
  { display: 'Session stopped with v/s without failure', value: 'Session stopped with v/s without failure' }
]

