import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UpdateFirmware } from 'src/app/models/charge-point-actions/update-firmware';
import { ChargingActionsService } from 'src/app/services/charging-actions.service';


@Component({
  selector: 'app-get-diagnostic',
  templateUrl: './get-diagnostic.component.html',
  styleUrls: ['./get-diagnostic.component.scss']
})
export class GetDiagnosticComponent implements OnInit {

  public currentDate: Date = new Date(new Date().getDate());
  constructor(private actionService: ChargingActionsService,private dialogRef:DynamicDialogRef) { }

  getDiagnosticModel: UpdateFirmware;
  getDiagnosticForm: UntypedFormGroup;

  ngOnInit(): void {
    this.getDiagnosticForm = new UntypedFormGroup({
      url: new UntypedFormControl(''),
      retries: new UntypedFormControl(null),
      retryInterval: new UntypedFormControl(null),
      startTime: new UntypedFormControl(null),
      stopTime: new UntypedFormControl(null),
      reason: new UntypedFormControl(''),
    });
  }

  onSubmit() {
    this.getDiagnosticModel = this.getDiagnosticForm.value;
  }

  onReset() {
    this.getDiagnosticForm.reset();
  }

}
