<div class="row" id="updateFirmware">
    <div class="col-xl-12">
        <form [formGroup]="updateFirmawareForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
            <table class="table mb-0 table-bordered table-borderless create-evse">
                <tbody class="border-none">
                   
                    <tr *ngIf="isBulkUpdate">
                        <td>
                            Select ChargePoints
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <p-multiSelect [options]="chargePointData" appendTo="body" [filter]="true" [showClear]="true"
                                [showToggleAll]="true" [maxSelectedLabels]="2" selectedItemsLabel="{0} Items Selected"
                                optionLabel="chargePointName" [virtualScroll]="true" [virtualScrollItemSize]="43" formControlName="chargePoint"
                                class="multiselect-custom-virtual-scroll" placeholder="Select Charge Point" optionValue="chargePointId"
                                (onSelectAllChange)="onSelectAllChange($event)" (onChange)="selectChargePoint($event)"></p-multiSelect>
                            <div *ngIf="chargePoint.invalid && (chargePoint.touched || chargePoint.dirty)" class="invalid-feedback ">
                                <span *ngIf="chargePoint.errors?.required ">ChargePoint is required
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="!isBulkUpdate">
                        <td>
                            Firmware Type
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <p-dropdown *ngIf="chargePointTypeValue === chargePointTypeEnum.AC" appendTo="body" [options]="chargePointACTypeData" optionValue="value" optionLabel="display" 
                            formControlName="firmwareType" (onChange)="changeFirmwareType($event)" placeholder="Select Firmware Type">
                            </p-dropdown>
                            <p-dropdown *ngIf="chargePointTypeValue === chargePointTypeEnum.DC" appendTo="body" [options]="chargePointDCTypeData" optionValue="value" optionLabel="display" 
                            (onChange)="changeFirmwareType($event)" placeholder="Select Firmware Type">
                            </p-dropdown>
                            <div *ngIf="firmwareType.invalid && (firmwareType.touched || firmwareType.dirty)" class="invalid-feedback ">
                                <span *ngIf="firmwareType.errors?.required ">Firmware Type is required
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="!isBulkUpdate">
                        <td>
                            Version
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <p-dropdown appendTo="body" [options]="displayVersionData" optionValue="value" optionLabel="display" 
                                formControlName="firmwareDetailId" placeholder="Select Version">
                            </p-dropdown>
                            <div *ngIf="firmwareDetailId.invalid && (firmwareDetailId.touched || firmwareDetailId.dirty)" class="invalid-feedback ">
                                <span *ngIf="firmwareDetailId.errors?.required ">Version is required
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Retrive After
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <p-calendar formControlName="retrieveAfter" [showIcon]="true" appendTo=body [minDate]="minDate" 
                            [showTime]="true" dateFormat="dd-MM-yy" [showIcon]="true"></p-calendar>
                            <div *ngIf="retrieveAfter.invalid && (retrieveAfter.touched || retrieveAfter.dirty)" class="invalid-feedback ">
                                <span *ngIf="retrieveAfter.errors?.required ">Retrive After is required
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Retries
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="number" max="3" min="0" formControlName="retries" class="form-control" />
                            <div *ngIf="retries.invalid && (retries.touched || retries.dirty)" class="invalid-feedback ">
                                <span *ngIf="retries.errors?.required ">Retries is required
                                </span>
                            </div>
                            <div *ngIf="retries.invalid && (retries.touched || retries.dirty)" class="invalid-feedback ">
                                <span *ngIf="retries.errors?.max ">Retries must be between 0 to 3
                                </span>
                            </div>
                            <div *ngIf="retries.invalid && (retries.touched || retries.dirty)" class="invalid-feedback ">
                                <span *ngIf="retries.errors?.min ">Retries must be between 0 to 3
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Interval (In Minutes)
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="number" min="1" formControlName="retryInterval" class="form-control" />
                            <div *ngIf="retryInterval.invalid && (retryInterval.touched || retryInterval.dirty)" class="invalid-feedback ">
                                <span *ngIf="retryInterval.errors?.required ">Interval is required
                                </span>
                            </div>
                            <div *ngIf="retryInterval.invalid && (retryInterval.touched || retryInterval.dirty)" class="invalid-feedback ">
                                <span *ngIf="retryInterval.errors?.min ">Interval must be greater than 0
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Reason for update
                            <span class="text-danger text-sm "><sup>*</sup></span>
                        </td>
                        <td>
                            <input type="text" formControlName="reason" class="form-control" />
                            <div *ngIf="reason.invalid && (reason.touched || reason.dirty)" class="invalid-feedback ">
                                <span *ngIf="reason.errors?.required ">Reason is required
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="mt-3 text-center">
                            <div class="d-inline">
                                <input type="submit" [disabled]="!updateFirmawareForm.valid" class="btn btn-hes mr-3" value="Run Action">
                                <input type="reset" class="btn btn-danger" value="Clear">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>