import { UrlParameter } from './constant';

declare let webAPIBaseUrl: string;
declare let webAPILogBaseUrl: string;
declare let webCPOAPIBaseUrl: string;
declare let rttyBaseUrl: string;
declare let historyData: string;

export class History {
  static historyData: string = historyData;
}
export class ApiUrls {
  static webAPIBaseUrl: string = webAPIBaseUrl;
  static baseUrl: string = webAPIBaseUrl;
  static logBaseUrl: string = webAPILogBaseUrl;
  static ocplogBaseUrl: string = webCPOAPIBaseUrl;
  static rttyBaseUrl: string = rttyBaseUrl;

  //#region AuthenticateController
  static login = `${ApiUrls.baseUrl}/v1/auth/web/login`;
  static exchangeToken = `${ApiUrls.baseUrl}/v1/auth/exchangetoken`;
  //#endregion

  //#region OCPAuthentication
  static ocpLogin = `${ApiUrls.ocplogBaseUrl}/v1/auth/web/login`;
  //#endregion

  //#region Channels
  static channelType = `${ApiUrls.baseUrl}/v1/channel/channelType`;
  //#endregion

  //#region Reports
  static reportsInputs = `${ApiUrls.baseUrl}/v1/report/inputs`;
  static report = `${ApiUrls.baseUrl}/v1/report`;
  static shareReport = `${ApiUrls.baseUrl}/v1/${History.historyData}/software-cost-report`;
  static sessionRevenueReport = `${ApiUrls.baseUrl}/v1/${History.historyData}/session-revenue-charges`;
  static loReport = `${ApiUrls.baseUrl}/v1/${History.historyData}/service-cost-report`;
  static sessionHistory = `${ApiUrls.baseUrl}/v1/${History.historyData}/session-history`;
  static loList = `${ApiUrls.baseUrl}/v1/channel/land-owner-agency/{channelId}/lo`;
  static allLoList = `${ApiUrls.baseUrl}/v1/user/lo-user/list`;
  static sessionRevenueReportExport = `${ApiUrls.baseUrl}/v1/${History.historyData}/session-revenue-charges/export-to-excel`;
  static softwareCostReportExport = `${ApiUrls.baseUrl}/v1/${History.historyData}/software-cost-report/export-to-excel`;
  static evseConsumptionReport = `${ApiUrls.baseUrl}/v1/${History.historyData}/evse-consumption-report`;
  static chargePointUptimeReport = `${ApiUrls.logBaseUrl}/v1/charge-point/{chargePointId}/connection-status/history?interval={interval}&periodFrom={periodFrom}&periodTo={periodTo}&timezoneOffset={timezoneOffset}`;
  static evseDurationReport = `${ApiUrls.baseUrl}/v1/${History.historyData}/evse-charging-duration-report`;
  static sessionReport = `${ApiUrls.baseUrl}/v1/${History.historyData}/session-report`;
  static sessionReportExport = `${ApiUrls.baseUrl}/v1/${History.historyData}/session-report/export-to-excel`;
  static phonepeQRCodeReport = `${ApiUrls.baseUrl}/v1/${History.historyData}/dqr-request-history`;
  static phonepeRefundFetchStatus = `${ApiUrls.baseUrl}/v1/payment/phonepe/qr-code-check-status?transactionId={transactionId}`;
  static phonepeRefundAmount = `${ApiUrls.baseUrl}/v1/payment/phonepe/qr-code-refund`;
  static ocpiSessionReport = `${ApiUrls.baseUrl}/v1/${History.historyData}/ocpi-session-report`;
  static ocpiSessionReportExport = `${ApiUrls.baseUrl}/v1/${History.historyData}/ocpi-session-report/export-to-excel`;
  static ccReport = `${ApiUrls.baseUrl}/v1/charge-point-consumption/list`;
  static ccConsumption = `${ApiUrls.baseUrl}/v1/charge-point-consumption/groupwise-list`;
  static connectivityReport = `${ApiUrls.logBaseUrl}/v1/charge-point/{chargePointId}/connectivity-count?interval={interval}&periodFrom={periodFrom}&periodTo={periodTo}&timezoneOffset={timezoneOffset}`;
  static sessionStoppedReport = `${ApiUrls.baseUrl}/v1/${History.historyData}/charge-point/{chargePointId}/stop-error-count?interval={interval}&fromDate={fromDate}&toDate={toDate}&timezoneOffset={timezoneOffset}`;
  static preparingChargingReport=`${ApiUrls.logBaseUrl}/v1/charge-point/{chargePointId}/charging-status-count?interval={interval}&periodFrom={periodFrom}&periodTo={periodTo}&timezoneOffset={timezoneOffset}`;
  static errorCountReport=`${ApiUrls.logBaseUrl}/v1/charge-point/{chargePointId}/charging-error-count?interval={interval}&periodFrom={periodFrom}&periodTo={periodTo}&timezoneOffset={timezoneOffset}`;
  static errorDetails=`${ApiUrls.logBaseUrl}/v1/charge-point/{chargePointId}/charging-error-details?interval={interval}&periodFrom={periodFrom}&periodTo={periodTo}&timezoneOffset={timezoneOffset}`;
  //#endregion
 
  //#Partners
  static getPartners: string = ApiUrls.baseUrl + '/v1/partner';
  static getPartnerType: string = ApiUrls.baseUrl + '/v1/partner/types';
  static partnerCompanyDetail: string = ApiUrls.baseUrl + '/v1/company-detail/partner ';
  static getChargersByPartnerId: string = ApiUrls.baseUrl + '/v1/partner/{partnerId}/chargers';
  //#endregion

  // #Partners Contract
  static addPartnerContract: string = ApiUrls.baseUrl + '/v1/partner-contract';
  static partnerContractHistoryById: string = ApiUrls.baseUrl + '/v1/partner-contract-history';
  //#endregion

  //#region UserController
  static user = `${ApiUrls.baseUrl}/v1/user/register`;
  static registerCustomer = `${ApiUrls.baseUrl}/v1/auth/register-customer`;
  static updateUser = `${ApiUrls.baseUrl}/v1/user`;
  static userList = `${ApiUrls.baseUrl}/v1/user`;
  static userListPagination = `${ApiUrls.baseUrl}/v1/user/customers`;
  static userListExport = `${ApiUrls.baseUrl}/v1/user/customers/export-to-excel`;
  static availableUsersForStartSessionList = `${ApiUrls.baseUrl}/v1/user/available-for-charging`;
  static enabledUsers = `${ApiUrls.baseUrl}/v1/users/${UrlParameter.onlyEnabled}`;
  static deleteUser = `${ApiUrls.baseUrl}/v1/user/${UrlParameter.userId}`;
  static userProfile = `${ApiUrls.baseUrl}/v1/user/profile`;
  static changePassword = `${ApiUrls.baseUrl}/v1/user/change-password`;
  static forgotPassword = `${ApiUrls.baseUrl}/v1/user/web/forgot-password`;
  static resetPassword = `${ApiUrls.baseUrl}/v1/user/reset-password`;
  static createPassword = `${ApiUrls.baseUrl}/v1/user/create-password`;
  static verifyEmail = `${ApiUrls.baseUrl}/v1/user/validate/email-token`;
  static userRFIDTagList = `${ApiUrls.baseUrl}/v1/rfid-tag/user/${UrlParameter.userId}`;
  static setUserStatus = `${ApiUrls.baseUrl}/v1/user/status/${UrlParameter.userId}`;
  static getUserStatus = `${ApiUrls.baseUrl}/v1/user/status`;
  static getChargingSessionUserWise = `${ApiUrls.baseUrl}/v1/user`;
  static getChargingSessionAnalyticsUserWise = `${ApiUrls.baseUrl}/v2/user`;
  static addProfileImage = `${ApiUrls.baseUrl}/v1/user/profile/image`;
  static payOutstanding = `${ApiUrls.baseUrl}/v1/user/userId/pay-outstanding`;

  static settlement = `${ApiUrls.baseUrl}/v1/user/userId/settlement`;
  static settlementById = `${ApiUrls.baseUrl}/v1/settlement/settlementId`;
  static reservationByUserId = `${ApiUrls.baseUrl}/v1/user/userId/web/reservation`;
  static getChargingSessionUserWisePaging = `${ApiUrls.baseUrl}/v3/user/{userId}/session`;
  static downloadSessionDataByUserIdExcelfile = `${ApiUrls.baseUrl}/v1/user/{userId}/session/export-to-excel`;
  static downloadAuthorizationDataByUserIdExcelfile = `${ApiUrls.baseUrl}/v1/user/{userId}/authorization/export-to-excel`;
  static transactionListByUserId = `${ApiUrls.baseUrl}/v2/user/{userId}/transaction`;
  static vehicleListByUserId = `${ApiUrls.baseUrl}/v1/user/{userId}/vehicle/list`;
  //#endregion

  //#region Charge Point Location
  static locations = `${ApiUrls.baseUrl}/v1/location`;
  static locationsPagination = `${ApiUrls.baseUrl}/v1/location/list`;
  static location = `${ApiUrls.baseUrl}/v1/location`;
  static status = `${ApiUrls.baseUrl}/v1/location/status`;
  static createlocation = `${ApiUrls.baseUrl}/v1/location`;
  static updatelocation = `${ApiUrls.baseUrl}/v1/location`;
  static deletelocation = `${ApiUrls.baseUrl}/v1/location`;
  //#endregion

  //#region Location Image
  static getLocationImage = `${ApiUrls.baseUrl}/v1/location-image`;
  static getLocationImages = `${ApiUrls.baseUrl}/v1/location-image`;
  static uploadLocationImage = `${ApiUrls.baseUrl}/v1/location-image/upload`;
  static addLocationImage = `${ApiUrls.baseUrl}/v1/location-image`;
  static editLocationImage = `${ApiUrls.baseUrl}/v1/location-image`;
  static deleteLocationImage = `${ApiUrls.baseUrl}/v1/location-image`;
  //#endregion

  // #region Activity : Authorizations
  //static authorizationList = `${ApiUrls.baseUrl}/v2/authorization`;
  static authorizationList = `${ApiUrls.baseUrl}/v1/${History.historyData}/authorization`;
  static authorizationById = `${ApiUrls.baseUrl}/v1/authorization/{authorization_id}/history`
  //static authorization = `${ApiUrls.baseUrl}/v1/authorization`;
  static authorizationByUser = `${ApiUrls.baseUrl}/v1/user/{userId}/authorization`;
  static authorizationByChargePoint = `${ApiUrls.baseUrl}/v1/charge-point/{chargepointId}/authorization`;
  static downloadAuthorizationDataExcelfile = `${ApiUrls.baseUrl}/v1/${History.historyData}/authorization/export-to-excel`;
  static downloadAuthorizationDataByChargePointExcelfile = `${ApiUrls.baseUrl}/v1/charge-point/{chargepointId}/authorization/export-to-excel`;
  //#endregion

  // #region Activity : Reservation
  static reservationList = `${ApiUrls.baseUrl}/v1/reservationList`;
  static reservation = `${ApiUrls.baseUrl}/v1/reservation`;
  static reservationPagination = `${ApiUrls.baseUrl}/v1/${History.historyData}/reservation`;
  static reservationUpComingList = `${ApiUrls.baseUrl}/v1/reservation/list/{$filter}`;
  static cancelReservation = `${ApiUrls.baseUrl}/v1/user/web/reservation/{chargePointReservationId}/cancel`;
  static saveReservation = `${ApiUrls.baseUrl}/v1/charge-point/evse/action/admin/reserve-now`;
  static saveRfidReservation = `${ApiUrls.baseUrl}/v1/charge-point/evse/action/admin/rfid-reserve-now`;
  static reservationListByDate = `${ApiUrls.baseUrl}/v1/charge-point/evse/{chargePointEvseId}/web/reservation/{reservationDateEpoch}`;
  static changeAvailability = `${ApiUrls.baseUrl}/v1/charge-point/evse/action/change-availability`;
  static userFilter = `${ApiUrls.baseUrl}/v1/report/filter/filter-key/{$filterKey}/filter-value/{$filterValue}`;
  //#endregion

  // #region Activity : Charging Session
  static chargepointssession = `${ApiUrls.baseUrl}/v1/session`;
  static chargPointSessionHistory = `${ApiUrls.baseUrl}/v1/session/{sessionId}/history`;
  static chargepointssessionList = `${ApiUrls.baseUrl}/v2/session`;
  static chargepointsession = `${ApiUrls.baseUrl}/v2/charge-point/{chargePointId}/session`;
  static chargePointSessionCommunication = `${ApiUrls.baseUrl}/v2/session/{chargePointSessionId}/log/communication`;
  static chargePointSessionReqResLog = `${ApiUrls.baseUrl}/v1/session/{sessionId}/request-response-log`;
  static downloadSessionDataExcelfile = `${ApiUrls.baseUrl}/v1/${History.historyData}/service-cost-report/export-to-excel`;
  static downloadSessionHistoryExcelfile = `${ApiUrls.baseUrl}/v1/${History.historyData}/session-history/export-to-excel`;
  static downloadRequestResponseExcelfile = `${ApiUrls.baseUrl}/v1/session/{sessionId}/request-response-log/export-to-excel`;
  static downloadCommunicationBySessionIdExcelfile = `${ApiUrls.baseUrl}/v1/session/{sessionId}/log/communication/export-to-excel`;
  static getQrCodeHistory = `${ApiUrls.baseUrl}/v1/session/{sessionId}/qrcode-history/list`;
  //static chargePointSessionList = `${ApiUrls.baseUrl}/v1/history/session-history`;
  //#endregion

  // #region Activity : EVSE
  static connectorList = `${ApiUrls.baseUrl}/v1/connectorList`;

  static logList = `${ApiUrls.baseUrl}/v1/charge-point/evse/`;
  static communicationlogListByEvseId = `${ApiUrls.baseUrl}/v2/charge-point/evse/{chargePointEvseId}/log/communication`;

  static communicationDetailsLogListByEvseId = `${ApiUrls.logBaseUrl}/v1/client/{ocppClientName}/charge-point/{chargeBox}/connector/{connectorId}/log/communication`;



  static evseType = `${ApiUrls.baseUrl}/v1/charge-point/evse/types`;
  static evseStatus = `${ApiUrls.baseUrl}/v1/charge-point/evse/status`;
  static addEvse = `${ApiUrls.baseUrl}/v1/charge-point/evse`;
  static evsePhase = `${ApiUrls.baseUrl}/v1/charge-point/evse/phases`;
  static evseConnectedPhase = `${ApiUrls.baseUrl}/v1/charge-point/evse/connected-phase`;
  static evsePhaseRotation = `${ApiUrls.baseUrl}/v1/charge-point/evse/phase-rotations`;
  static evseQRCode = `${ApiUrls.baseUrl}/v1/charge-point/evse/get-unique-qrcode`;
  static evseNewConnectorId = `${ApiUrls.baseUrl}/v1/charge-point/{chargePointId}/evse/new-connector-id`;


  static evseStartSession: string = ApiUrls.baseUrl + '/v1/charge-point/evse/action/admin/start-session';
  static evseStopSession: string = ApiUrls.baseUrl + '/v1/charge-point/evse/action/admin/stop-session/';
  static evseTriggermessage: string = ApiUrls.baseUrl + '/v1/charge-point/evse/action/trigger-message/';
  static evseActiveSession: string = ApiUrls.baseUrl + '/v1/charge-point/evse/';

  static chargePointEVSETotalSession: string = ApiUrls.baseUrl + '/v1/charge-point/evse/';
  static reservationByEVSCId = `${ApiUrls.baseUrl}/v1/charge-point/evse/chargePointEvseId/web/reservation`;
  static reqResLogByEVSEId: string = ApiUrls.baseUrl + '/v1/charge-point/evse/{chargePointEvseId}/request-response-log';
  static downloadRequestResponceDataByEVSEIdExcelfile: string = ApiUrls.baseUrl + '/v1/charge-point/evse/{chargePointEvseId}/request-response-log/export-to-excel';
  static downloadCommunicationLogByEVSEIdExcelfile: string = ApiUrls.baseUrl + '/v1/charge-point/evse/{chargePointEvseId}/log/communication/export-to-excel';
  // #endregion


  // #Admin Charge Point
  static chargePoint: string = ApiUrls.baseUrl + '/v1/charge-point';
  static isChannelChangedCheck: string = ApiUrls.baseUrl + '/v1/charge-point/{chargePointId}/get-channel-changed';
  static chargePointV3: string = ApiUrls.baseUrl + '/v3/charge-point';
  static chargePointPagination: string = ApiUrls.baseUrl + '/v2/charge-point';
  static chargePointNetworkTypes: string = ApiUrls.baseUrl + '/v1/charge-point/network-types';
  static chargePointTypes: string = ApiUrls.baseUrl + '/v1/charge-point/types';
  static chargePointStatus: string = ApiUrls.baseUrl + '/v1/charge-point/status';
  static chargePointNetworkProtocols: string = ApiUrls.baseUrl + '/v1/charge-point/network-protocols';

  static cpActioncompositeschedule: string = ApiUrls.baseUrl + '/v1/charge-point/action/';
  static cpAction: string = ApiUrls.baseUrl + '/v1/charge-point/action/';
  static cpActionreset: string = ApiUrls.baseUrl + '/v1/charge-point/action/';
  static cpActionupdatefirmware: string = ApiUrls.baseUrl + '/v1/charge-point/action/';
  static cpActiondiagnostics: string = ApiUrls.baseUrl + '/v1/charge-point/action/';
  static cpActionclearcache: string = ApiUrls.baseUrl + '/v1/charge-point/action/';

  static chargePointActionLogs: string = ApiUrls.baseUrl + '/v1/charge-point/log/action';
  static chargePointActionLog: string = ApiUrls.baseUrl + '/v1/charge-point/log/action/charge-point/';
  static chargePointCommunicationLogs: string = ApiUrls.baseUrl + '/v1/charge-point/log/communication';
  static chargePointCommunicationnLog: string = ApiUrls.baseUrl + '/v1/charge-point/{chargePointId}/log/communication';

  static chargePointHardwareLog: string = ApiUrls.baseUrl + '/v1/charge-point/log/hardware/';
  static chargePointNetworkLog: string = ApiUrls.baseUrl + '/v1/charge-point/log/network/';
  static chargePointStatusLog: string = ApiUrls.baseUrl + '/v1/charge-point/status-log/';
  static reqResLogByChargePointId: string = ApiUrls.baseUrl + '/v1/charge-point/{chargePointId}/request-response-log';
  static detailsChargePointId: string = ApiUrls.logBaseUrl + '/v1/client/{ocppClientName}/charge-point/{chargeBox}/log/communication';
  static detailsSessionIdLog: string = ApiUrls.logBaseUrl + '/v1/transaction/{transactionId}/log/communication';


  static chargePointTotalSession: string = ApiUrls.baseUrl + '/v1/charge-point/';
  static chargePointEVSEByMaxPower: string = ApiUrls.baseUrl + '/v1/charge-point/by-max-power';


  static downloadSessionDataByChargePointIdExcelfile: string = ApiUrls.baseUrl + '/v1/charge-point/{chargePointId}/session/export-to-excel';
  static downloadRequestResponceDataByChargePointIdExcelfile: string = ApiUrls.baseUrl + '/v1/charge-point/{chargePointId}/request-response-log/export-to-excel';
  static cpActionDataTransfer: string = ApiUrls.baseUrl + '/v1/charge-point/action/data-transfer';
  static downloadCommunicationLogByChargePointIdExcelfile: string = ApiUrls.baseUrl + '/v1/charge-point/{chargePointId}/log/communication/export-to-excel';

  static getChargePointOwner = `${ApiUrls.baseUrl}/v1/charge-point/owner`;
  static chargePointListByCRM: string = ApiUrls.baseUrl + '/v1/user/{userId}/charge-point/list';
  static chargePointListByChannel: string = ApiUrls.baseUrl + '/v1/channel/charge-point/list/{channelId}/{channelType}';
  static downloadChargePointExcelfile: string = ApiUrls.baseUrl + '/v1/charge-point/export-to-excel';

  static terminal: string = ApiUrls.rttyBaseUrl + '/nebulaeconsole/{chargeBox}';

  static uploadChargePointImages: string = ApiUrls.baseUrl + '/v1/charge-point/{chargePointId}/images';
  static chargePointHistoryLogs: string = ApiUrls.baseUrl + '/v1/charge-point/historylogs';

  static totalCreditById: string = ApiUrls.baseUrl + '/v1/dashboard/{chargePointId}/total-credits';
  static unlockConnectorByEvseId: string = ApiUrls.baseUrl + '/v1/charge-point/evse/action/unlock-connector';
  //#endregion

  //#region Charge Point Configuration :
  static getChargePointConfigurations: string = ApiUrls.baseUrl + '/v1/charge-point-configuration/charge-point/';
  static getChargePointConfiguration: string = ApiUrls.baseUrl + '/v1/charge-point-configuration/';
  static updateChargePointConfiguration: string = ApiUrls.baseUrl + '/v1/charge-point-configuration/';
  //#endregion

  // #region Activity : EVSE Connectors
  static evseConnectorType = `${ApiUrls.baseUrl}/v1/charge-point/evse/connector/type`;
  static evseConnectorFormat = `${ApiUrls.baseUrl}/v1/charge-point/evse/connector/format`;
  static evseConnectorStatus = `${ApiUrls.baseUrl}/v1/charge-point/evse/connector/status`;
  static getevseConnector = `${ApiUrls.baseUrl}/v1/charge-point/evse/connector/`;
  static addEvseConnector = `${ApiUrls.baseUrl}/v1/charge-point/evse/connector`;
  //#endregion

  //#region ChargingZonesController
  static getChargingZone: string = ApiUrls.baseUrl + `/v1/charging-zone/`;
  static getChargingZones: string = ApiUrls.baseUrl + `/v1/charging-zone`;
  static getChargingZonesPagination: string = ApiUrls.baseUrl + `/v1/charging-zone/list`;
  static getChargePointForChargingZone: string = ApiUrls.baseUrl + `/v1/charging-zone/`;
  static getChargingZoneStatus: string = ApiUrls.baseUrl + `/v1/charging-zone/status`;
  static addChargingZone = ApiUrls.baseUrl + `/v1/charging-zone`;
  static updateChargingZone = ApiUrls.baseUrl + `/v1/charging-zone`;
  static deleteChargingZone: string = ApiUrls.baseUrl + `/v1/charging-zone`;
  //#endregion

  //#region ReservationTariff
  static getReservationTariffTypes: string = ApiUrls.baseUrl + `/v1/reservation-tariff/types`;
  static reservationTariff: string = ApiUrls.baseUrl + `/v1/reservation-tariff`;
  static reservationById: string = ApiUrls.baseUrl + `/v1/reservation-tariff/{reservationTariffId}`;
  static checkReservation: string = ApiUrls.baseUrl + `/v1/charge-point/evse/{evseId}/web/reservation/latest/exclude-user/{excludeUseId}`;
  static tariffHistoryById: string = ApiUrls.baseUrl + `/v1/reservation-tariff/{reservationTariffId}/history`;
  static evseListByReservationTariffId: string = ApiUrls.baseUrl + `/v1/reservation-tariff/{reservationTariffId}/evse`;
  //#end region

  // #Admin Module Urls
  static adminRoles: string = ApiUrls.baseUrl + '/v1/role';
  static adminLocales: string = ApiUrls.baseUrl + '/v1/admin/locales';
  static admin: string = ApiUrls.baseUrl + '/v1/admin';
  static adminUser: string = ApiUrls.baseUrl + '/v1/user';
  static adminList: string = ApiUrls.baseUrl + '/v1/user/list';
  static partnerAdmin: string = ApiUrls.baseUrl + '/v1/partner/admin';
  static clientAdmin: string = ApiUrls.baseUrl + '/v1/client';
  //#endregion

  //#region Dashboard API URLS
  static faults: string = ApiUrls.baseUrl + '/v1/dashboard/faults';
  static revenue: string = ApiUrls.baseUrl + '/v1/dashboard/revenue';
  static newMobileUser: string = ApiUrls.baseUrl + '/v1/dashboard/new-mobile-user';
  static newChargePoint: string = ApiUrls.baseUrl + '/v1/dashboard/new-charge-points';
  static locationByCity: string = ApiUrls.baseUrl + '/v1/dashboard/location-by-city';
  static chargePointByAccessType: string = ApiUrls.baseUrl + '/v1/dashboard/charge-point-by-access-type';
  static activeChargePointSessions: string = ApiUrls.baseUrl + '/v1/dashboard/active-charge-point-sessions';
  static chargePointSession: string = ApiUrls.baseUrl + '/v1/dashboard/charge-point-sessions';
  static totalEnergy: string = ApiUrls.baseUrl + '/v1/dashboard/total-energy?range=';
  //#endregion

  // #RFID Module Urls
  static rfidTag: string = ApiUrls.baseUrl + '/v1/rfid-tag';
  static rfidTagPagination: string = ApiUrls.baseUrl + '/v2/rfid-tag';
  static rfidTagStatus: string = ApiUrls.baseUrl + '/v1/rfid-tag/status';
  static getChargingSessionByRfid: string = ApiUrls.baseUrl + '/v1/rfid-tag/{rfidTagId}/sessions';
  static downloadSessionDataByRfidExcelfile: string = ApiUrls.baseUrl + '/v1/rfid-tag/{rfidTagId}/sessions/export-to-excel';
  //#endregion
  //#User tag Module Urls
  static userTag: string = ApiUrls.baseUrl + '/v1/user/{userId}/user-tag/list';
  //#endregion
  //#region StatusLog
  static statusLog: string = ApiUrls.baseUrl + '/v1/log/status/';
  //#endregion

  //#region Communication Log
  static communicationLog: string = ApiUrls.baseUrl + '/v2/log/communication/';
  static communicationLogDetail: string = ApiUrls.baseUrl + '/v1/log/communication/{communicationId}';
  static downloadCommunicationExcelfile: string = ApiUrls.baseUrl + '/v1/log/communication/export-to-excel';
  //#endregion

  //#region Request-Response Log
  static reqResLogById: string = ApiUrls.baseUrl + '/v1/request-response-log/';
  //#endregion


  //#region Clients
  static client: string = ApiUrls.baseUrl + '/v1/client';
  static updateClient: string = ApiUrls.baseUrl + '/v1/client';
  static clientTypes: string = ApiUrls.baseUrl + '/v1/client/types';
  static deleteClientAdmin: string = ApiUrls.baseUrl + '/v1/client/admin';
  //#endregion

  //#region Client Detail
  static clientDetail: string = ApiUrls.baseUrl + '/v1/client-detail';
  //#endregion

  //#region tariff
  static tariffTypes: string = ApiUrls.baseUrl + '/v1/tariff/types';
  static tariff: string = ApiUrls.baseUrl + '/v1/tariff';
  static tariffPagination: string = ApiUrls.baseUrl + '/v1/tariff/list';
  static evseListByTariffId: string = ApiUrls.baseUrl + `/v1/tariff/{tariffId}/evse/list`;
  static tariffHistoryByTariffId: string = ApiUrls.baseUrl + `/v1/tariff/{tariffId}/history`;
  //#endregion

  //#region currency
  static currency: string = ApiUrls.baseUrl + '/v1/currency';
  //#endregion

  //#region payment processor
  static paymentProcessor: string = ApiUrls.baseUrl + '/v1/payment/client/processor';
  static getPaymentProcessor: string = ApiUrls.baseUrl + '/v1/payment/processor';
  //#endregion

  //#region Razorpay Transaction
  static razorpayTransaction: string = ApiUrls.baseUrl + '/v1/payment/razorpay/transaction';
  static razorpayTransactionList: string = ApiUrls.baseUrl + '/v1/payment/razorpay/transaction/list';
  static phonepeTransactionList: string = ApiUrls.baseUrl + '/v1/payment/phonepe/transaction/list';
  static phonepeTransaction: string = ApiUrls.baseUrl + '/v1/payment/phonepe/transaction/{$phonepeOrderId}';
  static phonepeTransactionFetchStatus: string = ApiUrls.baseUrl + '/v1/payment/phonepe/transaction/{$merchantTransactionId}/fetch-status';
  static stripeTransactionList: string = ApiUrls.baseUrl + '/v1/payment/stripe/transaction/list';
  static stripeTransactionFetchStatus: string = ApiUrls.baseUrl + '/v1/payment/stripe/transaction/{$orderId}/fetch-status';
  //#endregion

  //#region Transactions
  static transaction: string = ApiUrls.baseUrl + '/v2/transaction';
  static transactionList = `${ApiUrls.baseUrl}/v1/${History.historyData}/transaction`;
  static transactionById: string = ApiUrls.baseUrl + '/v1/transaction/{transactionId}';
  static transactionHistoryById = `${ApiUrls.baseUrl}/v1/${History.historyData}/transaction/{sessionReceiptId}`;
  //#endregion

  //#region Wallet
  static wallet: string = ApiUrls.baseUrl + '/v1/user';
  static walletByUserId = `${ApiUrls.baseUrl}/v1/${History.historyData}/user/{userId}/wallet/transaction`;
  static walletById = `${ApiUrls.baseUrl}/v1/${History.historyData}/user/wallet/transaction/{transactionId}`;
  //#endregion

  //#region SigFox
  static sigfox: string = ApiUrls.baseUrl + '/v1/sigfox';
  //#endregion

  //#region Device activity
  static deviceActivity: string = ApiUrls.baseUrl + '/v1/device-activity';
  //#endregion

  static country = 'https://api.countrystatecity.in/v1/countries';
  static countryList = ApiUrls.baseUrl + '/v1/country/list';
  static timeZones = ApiUrls.baseUrl + '/v1/system/timezones';
  static paymentProcessorType = ApiUrls.baseUrl + '/v1/payment/processor';

  //#region FAQ
  static faqList = `${ApiUrls.baseUrl}/v1/faq`;
  static addFaq = `${ApiUrls.baseUrl}/v1/faq`;
  static deleteFaq = `${ApiUrls.baseUrl}/v1/faq`;
  static getFaq = `${ApiUrls.baseUrl}/v1/faq`;
  static faqListForWebView = `${ApiUrls.baseUrl}/v1/client/{clientKey}/faq`;
  static mappedClient = `${ApiUrls.baseUrl}/v1/faq/{faqId}/mapping`;
  static clientDopdown: string = ApiUrls.baseUrl + '/v1/client/list';
  //#endregion

  //#region FAQ
  static amenityList = `${ApiUrls.baseUrl}/v1/amenities`;
  static addAmenityFaq = `${ApiUrls.baseUrl}/v1/amenities`;
  static deleteAmenity = `${ApiUrls.baseUrl}/v1/amenities`;
  static getamenity = `${ApiUrls.baseUrl}/v1/amenities`;
  static clientAmenityList = `${ApiUrls.baseUrl}/v1/client-amenities`;
  //#endregion

  //#region Templete
  static templateList = `${ApiUrls.baseUrl}/v1/sms-template`;
  static templateAddEdit = `${ApiUrls.baseUrl}/v1/sms-template`;
  static templateGetById = `${ApiUrls.baseUrl}/v1/sms-template`;
  static templateType = `${ApiUrls.baseUrl}/v1/sms-template/sms-types`;
  //#endregion

  //#region Push-Notification
  static pushNotifyTemplateType = `${ApiUrls.baseUrl}/v1/pushnotification/types`;
  static pushNotifyTemplateGetById = `${ApiUrls.baseUrl}/v1/pushnotification`;
  static pushNotifyTemplateAdd = `${ApiUrls.baseUrl}/v1/pushnotification/template`;
  static pushNotifyTemplateEdit = `${ApiUrls.baseUrl}/v1/pushnotification`;
  static pushNotifyTemplateList = `${ApiUrls.baseUrl}/v1/pushnotification`;
  static pushNotifyTemplateDelete = `${ApiUrls.baseUrl}/v1/pushnotification`;
  //#endregion
  //#region Custom-Push-Notification
  static customPushNotifyTemplateAdd = `${ApiUrls.baseUrl}/v1/PushNotification/custom`;
  //#endregion

  //#region Agency
  static addAgency = `${ApiUrls.baseUrl}/v1/channel`;
  static updateAgency = `${ApiUrls.baseUrl}/v1/channel/{channelId}`;
  static getChannelList = `${ApiUrls.baseUrl}/v1/channel/list`;
  static getParentAgencyListByType = `${ApiUrls.baseUrl}/v1/channel/channelType/{agencyType}`;
  static getStateList = `${ApiUrls.baseUrl}/v1/state/{countryId}/list`;
  static getAgencyById = `${ApiUrls.baseUrl}/v1/channel/{channelId}`;
  static deleteAgency = `${ApiUrls.baseUrl}/v1/agency-company/{channelId}`;
  static getSoftwareCost = `${ApiUrls.baseUrl}/v1/software-cost`;
  static getChildChannelList = `${ApiUrls.baseUrl}/v1/channel/{channelId}/child-channel-type/{channelType}`;
  static getOwnerList = `${ApiUrls.baseUrl}/v1/channel/owner/list`;
  static mapCarbonCreditChargePoint = `${ApiUrls.baseUrl}/v1/charge-point/carbon-credit`;
  //#endregion

  static getLoaUserList = `${ApiUrls.baseUrl}/v1/channel/all`;


  //#region SoftwareCost
  static softwareCostAddEdit = `${ApiUrls.baseUrl}/v1/software-cost`;
  static softwareCostUpdate = `${ApiUrls.baseUrl}/v1/software-cost/{$softwareCostId}`;
  //#endregion

  //#region CouponCode Request
  static couponCodeRequestList = `${ApiUrls.baseUrl}/v1/referal-coupon-mapping/pending-request/{landOwnerId}`;
  static approveRejectRequest = `${ApiUrls.baseUrl}/v1/referal-coupon-mapping`;
  static acceptedRequestList = `${ApiUrls.baseUrl}/v1/referal-coupon-mapping/accepted-request/{landOwnerId}`;
  static deleteApprovedUsers = `${ApiUrls.baseUrl}/v1/referal-coupon-mapping`;
  static allOwner = `${ApiUrls.baseUrl}/v1/charge-point/owner`;
  //#endregion
  static reportFilter = `${ApiUrls.baseUrl}/v1/report/filter/filter-key/{$filterKey}/filter-value/{$filterValue}`;

  // #region Charging Profile
  static chargingProfileInputsByChargePointId = `${ApiUrls.baseUrl}/v1/charging-profile/inputs`;
  static createChargingProfile = `${ApiUrls.baseUrl}/v1/charging-profile`;
  static updateChargingProfile = `${ApiUrls.baseUrl}/v1/charging-profile/{chargingProfileId}`;
  static getChargingProfile = `${ApiUrls.baseUrl}/v2/charging-profile`;
  static deleteChargingProfile = `${ApiUrls.baseUrl}/v1/charging-profile/{chargingProfileId}`;
  static getChargingProfileDetailById = `${ApiUrls.baseUrl}/v1/charging-profile/{chargingProfileId}`;
  static getChargingProfileListByChargePoint = ApiUrls.baseUrl + '/v1/charging-profile/chargePoint/{chargePointId}';
  static getChargingProfileListByConnector = ApiUrls.baseUrl + '/v1/charging-profile/chargePointEvse/{chargePointEvseId}';
  static setChargingProfile = ApiUrls.baseUrl + '/v1/charging-profile/{chargingProfileId}/set';
  static clearChargingProfile = ApiUrls.baseUrl + '/v1/charging-profile/{chargingProfileId}/clear';
  //#endregion

  //#region Vehicle Management
  static vehicleCompanyListPagination = `${ApiUrls.baseUrl}/v1/vehicle-company/list`;
  static vehicleCompanyList = `${ApiUrls.baseUrl}/v1/vehicle-company/list`;
  static addVehicleComapny = `${ApiUrls.baseUrl}/v1/vehicle-company`;
  static updateVehicleComapny = `${ApiUrls.baseUrl}/v1/vehicle-company/{vehicleCompanyId}`;
  static getVehicleCompanyById = `${ApiUrls.baseUrl}/v1/vehicle-company/{vehicleCompanyId}`;
  static deleteVehicleCompany = `${ApiUrls.baseUrl}/v1/vehicle-company/{vehicleCompanyId}`;
  static vehicleCompanyModelList = `${ApiUrls.baseUrl}/v1/vehicle-company/{vehicleCompanyId}/models`;

  static vehicleModelListPagination = `${ApiUrls.baseUrl}/v1/vehicle-model/list`;
  static addVehicleModel = `${ApiUrls.baseUrl}/v1/vehicle-model`;
  static updateVehicleModel = `${ApiUrls.baseUrl}/v1/vehicle-model/{vehicleModelId}`;
  static deleteVehicleModel = `${ApiUrls.baseUrl}/v1/vehicle-model/{vehicleModelId}`;
  static getVehicleModelById = `${ApiUrls.baseUrl}/v1/vehicle-model/{vehicleModelId}`;
  //#endregion

  //#region EMSP
  static emspListPagination = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/list`;
  static getEMSPById = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/{emspId}`;
  static createEMSP = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP`;
  static updateEMSPById = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/{emspId}`;
  static updateEmspAgreement = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/{emspId}/agreement`;
  static mapChargePoint = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/location`;
  static emspList = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP`;
  static getVersion = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/{emspId}/versions`;
  static resendTariff = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/tariff`;
  static getToken = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/{emspId}/token/list`;
  static deleteToken = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/{emspId}/token/delete`;
  static pullToken = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP/{emspId}/token?date_from={date_from}&date_to={date_to}&offset={offset}&limit={limit}`;
  //#endregion

  //#region CPO version
  static cpoVersionList = `${ApiUrls.ocplogBaseUrl}/ocpi/cpo/versions/list`;
  static getCpoVersionById = `${ApiUrls.ocplogBaseUrl}/ocpi/cpo/versions`;
  static createCpoVersion = `${ApiUrls.ocplogBaseUrl}/ocpi/cpo/versions`;
  static updateCpoVersion = `${ApiUrls.ocplogBaseUrl}/ocpi/cpo/versions`;
  static deleteCpoVersion = `${ApiUrls.ocplogBaseUrl}/ocpi/cpo/versions`;
  //#endregion

  //#region CPO Endpoints
  static cpoEndPointList = `${ApiUrls.ocplogBaseUrl}/cpo/endpoints/list`;
  static cpoEndpoint = `${ApiUrls.ocplogBaseUrl}/cpo/`
  static cpoEndPointById = `${ApiUrls.ocplogBaseUrl}/cpo/endpoints`
  static createCpoEndPoint = `${ApiUrls.ocplogBaseUrl}/cpo/endpoints`;
  static updateCpoEndPoint = `${ApiUrls.ocplogBaseUrl}/cpo/endpoints`;
  static deleteCpoEndpoint = `${ApiUrls.ocplogBaseUrl}/cpo/endpoints`;
  //#endregion

  //#region CPO Registration
  static cpo = `${ApiUrls.ocplogBaseUrl}/ocpi/cpo/1`;
  static addCpo = `${ApiUrls.ocplogBaseUrl}/ocpi/cpo`;
  static updateCpo = `${ApiUrls.ocplogBaseUrl}/ocpi/cpo`;

  static getLoaUserListForReports = `${ApiUrls.baseUrl}/v1/charge-point/liaisoning-agency-list`;
  static getLoUserListForReports = `${ApiUrls.baseUrl}/v1/charge-point/land-owner-list`;
  //#endregion

  //#region Carbon credit
  static getCarbonCreditAgency = `${ApiUrls.baseUrl}/v1/carbon-credit-agency/list`;
  static carbonCreditAgency = `${ApiUrls.baseUrl}/v1/carbon-credit-agency`;
  static getCarbonCreditAgencyById = `${ApiUrls.baseUrl}/v1/carbon-credit-agency/{carbonCreditAgencyId}`;
  //#endregion

  //#OCPI Session Report
  static getEmspList = `${ApiUrls.ocplogBaseUrl}/ocpi/EMSP`;
  //#endregion

  //#new Dashboard
  static getDashboardCount = `${ApiUrls.baseUrl}/v1/dashboard/count`;
  static totalCredit: string = ApiUrls.baseUrl + '/v1/dashboard/total-credits?range=';
  static energyConsumption: string = ApiUrls.baseUrl + '/v1/dashboard/energy-consumption';
  static annualRevenue: string = ApiUrls.baseUrl + '/v1/dashboard/revenue';
  //#endregion

  //#region Wallet Settlement
  static getWalletSettlementList = `${ApiUrls.baseUrl}/v1/wallet-transaction/list`;
  static getPendingWalletSettlementList = `${ApiUrls.baseUrl}/v1/wallet-transaction-request/list`;
  static getEMSPWalletSettlementList = `${ApiUrls.baseUrl}/v1/wallet-transaction-request/emsp/list`;
  static getWalletSettlementById = `${ApiUrls.baseUrl}/v1/wallet-transaction-request`;
  static getWalletBalanceByUserId = `${ApiUrls.baseUrl}/v1/wallet/total-balance?userId={userId}`;
  static createWalletSettlementEMSP = `${ApiUrls.baseUrl}/v1/wallet-transaction-request/emsp`;
  static createWalletSettlement = `${ApiUrls.baseUrl}/v1/wallet-transaction-request`;
  static updateWalletSettlement = `${ApiUrls.baseUrl}/v1/wallet-transaction-request/{transactionRequestId}`;
  static approveRejectWalletSettlement = `${ApiUrls.baseUrl}/v1/wallet-transaction-request/status/{transactionRequestId}`;
  //#endregion

  //#region Compact Charge Point
  static compactChargerSharedRequestList = `${ApiUrls.baseUrl}/v1/charge-point/{chargePointId}/compact/share-requests`;
  static chargerTransferRequest = `${ApiUrls.baseUrl}/v1/charge-point/{chargePointId}/transfer-request`;
  static updateTransferRequest = `${ApiUrls.baseUrl}/v1/user/charge-point/update-transfer-request`;
  static userSearchByPhoneEmail = `${ApiUrls.baseUrl}/v1/user/search`;
  //#endregion
  
   //#region Firmware Update
  static  addfirmwareDetails= `${ApiUrls.baseUrl}/v1/firmware-detail`;
  static  firmwareDetailsList = `${ApiUrls.baseUrl}/v1/firmware-detail`;
  static  firmwareDetailsDelete = `${ApiUrls.baseUrl}/v1/firmware-detail`;
  static  firmwareUpdateHistoryByChargePoint = `${ApiUrls.baseUrl}/v1/charge-point/chargePointId/firmware-update/history`;
  static  firmwareUpdateHistoryByfirmwareDetails = `${ApiUrls.baseUrl}/v1/firmware-detail/firmwareDetailId/history`;
  static  firmwareUpdateHistoryList = `${ApiUrls.baseUrl}/v1/firmware-update/history`;
  static  downloadFirmwareUpdateExcelFile = `${ApiUrls.baseUrl}/v1/firmware-update/export-to-excel`;
  static  getChargePointByType =  `${ApiUrls.baseUrl}/v1/charge-point/type/{chargePointType}`;
  //#endregion
}
