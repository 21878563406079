<div class="row">
    <div class="col-xl-12">
        <form [formGroup]="getDiagnosticForm" (ngSubmit)="onSubmit()" (reset)="onReset()">
            <table class="table mb-0 table-bordered table-borderless create-evse">
                <tbody class="border-none">
                    <tr>
                        <td>
                            Reason
                        </td>
                        <td>
                            <input type="text" formControlName="reason" class="form-control" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            URI
                        </td>
                        <td>
                            <input type="text" formControlName="url" class="form-control" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Retries
                        </td>
                        <td>
                            <input type="text" formControlName="retries" class="form-control" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Interval
                        </td>
                        <td>
                            <input type="text" formControlName="retryInterval" class="form-control" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Start time
                        </td>
                        <td>
                           <!-- <kendo-datetimepicker [(value)]="currentDate" formControlName="startTime" [format]="'dd-MM-yyyy HH:mm:ss'"></kendo-datetimepicker> -->
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Stop time
                        </td>
                        <td>
                            <!-- <kendo-datetimepicker [(value)]="currentDate" formControlName="stopTime" [format]="'dd-MM-yyyy HH:mm:ss'">
                            </kendo-datetimepicker> -->
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="mt-3 text-center">
                            <div class="d-inline">
                                <input type="submit" class="btn btn-hes mr-3" value="Run Action">
                                <input type="reset" class="btn btn-danger" value="Cancel">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>